import React, {useState} from "react";
import {Error} from "../Error";
import {Loader} from "../Loader";
import {Pagination} from "../Pagination";
import {sendProduct} from "../../actions/sales-agent/sendProduct";
import {useGetSalesAgentProductStatesQuery} from "../../store/sales-agent-product-state-api";
import {cutTags} from "../selectors/functions";

interface SalesAgentProductStateListProps {
    cityID: number
    initPage: number
    initPageSize: number
    initWitemSearch: string
    onSelectPageHandler: (page: number) => void
    onSelectPageSizeHandler: (pageSize: number) => void
    onChangeWitemSearch: (witemSearch: string) => void
}

export function SalesAgentProductStateList(
    {cityID, initPage, initPageSize, initWitemSearch, onSelectPageSizeHandler, onSelectPageHandler, onChangeWitemSearch}: SalesAgentProductStateListProps
) {

    const [page, setPage] = useState<number>(initPage)
    const [pageSize, setPageSize] = useState<number>(initPageSize)
    const [witemSearch, setWitemSearch] = useState<string>(initWitemSearch)
    const {data: response, isLoading, error} = useGetSalesAgentProductStatesQuery({city_id: cityID, page: page, page_size: pageSize, witem_search: witemSearch ? witemSearch : null})

    const setPageSizeHandler = (pageSize: number) => {
        setPageSize(pageSize)
        onSelectPageSizeHandler(pageSize)
    }
    const setPageHandler = (page: number) => {
        setPage(page)
        onSelectPageHandler(page)
    }

    const [sendProductLoading, setSendProductLoading] = useState(false)
    const [sendProductError, setSendProductError] = useState<string>('')

    const onClickHandler = (witemID: number) => {
        setSendProductError('')
        setSendProductLoading(true)

        sendProduct({
            witemIDs: [witemID],
            cityID: cityID,
            onSendHandler: productSendHandler
        })
    }

    const productSendHandler = (error: string) => {
        setSendProductError(error)
        setSendProductLoading(false)
    }

    const searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWitemSearch(event.target.value)
        onChangeWitemSearch(event.target.value)
    }

    return (
        <div>
            <div>
                <h4 className={'text-xl'}>Список товаров торговых агентов</h4>
            </div>
            {error && <Error error={JSON.stringify(error)}/>}
            {sendProductError && <Error error={sendProductError}/>}
            {(isLoading || sendProductLoading) && <Loader/>}
            <Pagination
                initPage={initPage}
                initPageSize={initPageSize}
                allCount={response ? response.all_count : 0}
                onSelectPageHandler={setPageHandler}
                onSelectPageSizeHandler={setPageSizeHandler}/>
            <div>
                <label>Поиск товара: </label>
                <input
                    className={'border'}
                    type={'string'}
                    onChange={searchChangeHandler}
                    value={witemSearch}
                    placeholder={'Поиск по товару...'}
                />
            </div>
            <table className={'mt-5 table-auto max-[1100px]:w-full'}>
                <thead className={'bg-blue-200 max-[1100px]:hidden'}>
                <tr>
                    <th className={'border border-gray-200'}>
                        ID товара
                    </th>
                    <th className={'border border-gray-200'}>
                        Описание
                    </th>
                    <th className={'border border-gray-200'}>
                        Успешно
                    </th>
                    <th className={'w-52 border border-gray-200'}>
                        Дата обновления
                    </th>
                    <th colSpan={5} className={'border border-gray-200'}>
                        Действия
                    </th>
                </tr>
                </thead>
                <tbody>
                {response && response.sales_agent_product_states.map(productState =>
                    <tr className={'border-b border-b-gray-200'} key={productState.id}>
                        <td className={'p-3 max-[1100px]:hidden'}>
                            {productState.witem.id}
                        </td>
                        <td className={'p-3 max-[1100px]:hidden'}>
                            {productState.description}
                        </td>
                        <td className={'p-3 max-[1100px]:hidden'}>
                            {productState.is_successful ?
                                <div className={'w-5 h-5 bg-green-500 rounded-full'}></div> :
                                <div className={'w-5 h-5 bg-red-500 rounded-full'}></div>
                            }
                        </td>
                        <td className={'p-3 max-[1100px]:hidden'}>
                            {productState.update_time}
                        </td>
                        <td className={'p-3 max-[1100px]:hidden'}>
                            <a target={'_blank'}
                               rel="noreferrer"
                               href={`http://${process.env.REACT_APP_OLD_ADMIN_HOST}/manage/index.php?type=witem&action=edit&id=${productState.witem.id}`}
                            >Правка</a>
                        </td>
                        <td className={'p-3 w-36 max-[1100px]:hidden'}>
                            <a target={'_blank'}
                               rel="noreferrer"
                               href={`http://${process.env.REACT_APP_OLD_ADMIN_HOST}/manage/index.php?type=witem&id=${productState.witem.id}`}
                            >Фото товара</a>
                        </td>
                        <td className={'p-3 max-[1100px]:hidden'}>
                            <a target={'_blank'}
                               rel="noreferrer"
                               href={`http://${process.env.REACT_APP_OLD_ADMIN_HOST}/manage/index.php?type=witem&action=wcitem&id=${productState.witem.id}`}
                            >Характеристики</a>
                        </td>
                        <td className={'p-3 max-[1100px]:hidden'}>
                            <a target={'_blank'}
                               rel="noreferrer"
                               href={`http://${process.env.REACT_APP_OLD_ADMIN_HOST}/manage/index.php?type=pware&witemid=${productState.witem.id}`}
                            >Цены</a>
                        </td>
                        <td className={'p-3 max-[1100px]:hidden'}>
                            <button
                                className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'}
                                onClick={() => onClickHandler(productState.witem.id)}
                            >Отправить
                            </button>
                        </td>
                        <td className={'p-3 w-full border break-words border-b-gray-300 border-r-0 border-l-0 min-[1101px]:hidden'}>
                            <div className={'flex w-full justify-between max-[550px]:flex-wrap'}>
                                <div className={'mr-5 flex-col justify-start w-full'}>
                                    <div>
                                        <b>ID товара:</b> {productState.witem.id}
                                    </div>
                                    <div>
                                        <b>Описание:</b> {productState.description}
                                    </div>
                                    <div className={'flex items-center'}>
                                        <div><b>Успешно:</b></div>
                                        <div className={'w-5 h-5 ml-3 rounded-full ' + (productState.is_successful ? 'bg-green-500' : 'bg-red-500')}></div>
                                    </div>
                                </div>
                                <div className={'flex-col justify-start'}>
                                    <b>Действия:</b><br/>
                                    <a target={'_blank'}
                                       rel="noreferrer"
                                       href={`http://${process.env.REACT_APP_OLD_ADMIN_HOST}/manage/index.php?type=witem&action=edit&id=${productState.witem.id}`}
                                    >Правка</a><br/>
                                    <a target={'_blank'}
                                       rel="noreferrer"
                                       href={`http://${process.env.REACT_APP_OLD_ADMIN_HOST}/manage/index.php?type=witem&id=${productState.witem.id}`}
                                    >Фото товара</a><br/>
                                    <a target={'_blank'}
                                       rel="noreferrer"
                                       href={`http://${process.env.REACT_APP_OLD_ADMIN_HOST}/manage/index.php?type=witem&action=wcitem&id=${productState.witem.id}`}
                                    >Характеристики</a><br/>
                                    <a target={'_blank'}
                                       rel="noreferrer"
                                       href={`http://${process.env.REACT_APP_OLD_ADMIN_HOST}/manage/index.php?type=pware&witemid=${productState.witem.id}`}
                                    >Цены</a><br/>
                                    <button
                                        className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'}
                                        onClick={() => onClickHandler(productState.witem.id)}
                                    >Отправить
                                    </button>
                                </div>
                            </div>
                            <div className={'mt-5  text-gray-600'}>
                                <p>Дата
                                    обновления: <b>{`${(new Date(productState.update_time)).toLocaleDateString()} ${(new Date(productState.update_time)).toLocaleTimeString()}`}</b>
                                </p>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

        </div>
    )

}
