import {ISupply} from "../models";
import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {prepareHeadersHandler, responseHandler} from "./functions";

interface SupplyListResponse {
    supplies: ISupply[]
}

export const suppliesApi = createApi({
    reducerPath: 'suppliesApi',
    tagTypes: ['Supplies'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/supply',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getSupplies: builder.query<ISupply[], {}>({
            query: () => ({
                url: 'list',
                method: 'POST',
                body: {},
            }),
            transformResponse: (baseQueryReturnValue: SupplyListResponse): ISupply[] => baseQueryReturnValue.supplies,
            transformErrorResponse(baseQueryReturnValue: BaseQueryError<any>): string {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.map(({id}) => ({type: 'Supplies' as const, id})),
                    {type: 'Supplies', id: 'LIST'},
                ]
                : [{type: 'Supplies', id: 'LIST'}],
        }),
    })
})

export const {useGetSuppliesQuery} = suppliesApi;
