import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {prepareHeadersHandler, responseHandler} from "./functions";
import {ILogEntry} from "../models";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

interface ProductPriceRuleLogEntriesListResponse {
    product_price_rule_log_entries: ILogEntry[],
    page: number,
    per_page: number,
    total_pages: number,
    all_count: number
}

export const productPriceRuleLogEntriesApi = createApi({
    reducerPath: 'productPriceRuleLogEntriesApi',
    tagTypes: ['ProductPriceRuleLogEntries'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/product-price-rule-log-entries',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getProductPriceRuleLogEntries: builder.query<ProductPriceRuleLogEntriesListResponse, { page: number, perPage: number, productPriceRuleID: string|null, userLogin: string|null }>({
            query: (arg) => ({
                url: 'list',
                method: 'POST',
                body: {
                    "page": arg.page,
                    "per_page": arg.perPage,
                    "product_price_rule_id": arg.productPriceRuleID,
                    "user_login": arg.userLogin,
                },
            }),
            transformErrorResponse(baseQueryReturnValue: BaseQueryError<any>): string {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.product_price_rule_log_entries.map(({id}) => ({type: 'ProductPriceRuleLogEntries' as const, id})),
                    {type: 'ProductPriceRuleLogEntries', id: 'LIST'},
                ]
                : [{type: 'ProductPriceRuleLogEntries', id: 'LIST'}],
        }),
    })
})

export const {
    useGetProductPriceRuleLogEntriesQuery
} = productPriceRuleLogEntriesApi
