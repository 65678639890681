import {configureStore} from "@reduxjs/toolkit";
import {citiesApi} from "./cities-api";
import {waresApi} from "./wares-api";
import {productPriceRuleApi} from "./product-price-rule-api";
import {brandsApi} from "./brands-api";
import {suppliesApi} from "./supplies-api";
import {productPriceApi} from "./product-price-api";
import {supplyProductApi} from "./supply-product-api";
import {authenticationApi} from "./authentication-api";
import {salesAgentProductStateApi} from "./sales-agent-product-state-api";
import {productPriceChangesApi} from "./product-price-changes-api";
import {supplyProductChangesApi} from "./supply-product-changes-api";
import {productPriceRuleLogEntriesApi} from "./product-price-rule-log-entries-api";
import {filesApi} from "./files-api";

export const store = configureStore({
    reducer: {
        [citiesApi.reducerPath]: citiesApi.reducer,
        [waresApi.reducerPath]: waresApi.reducer,
        [productPriceRuleApi.reducerPath]: productPriceRuleApi.reducer,
        [brandsApi.reducerPath]: brandsApi.reducer,
        [suppliesApi.reducerPath]: suppliesApi.reducer,
        [productPriceApi.reducerPath]: productPriceApi.reducer,
        [supplyProductApi.reducerPath]: supplyProductApi.reducer,
        [authenticationApi.reducerPath]: authenticationApi.reducer,
        [salesAgentProductStateApi.reducerPath]: salesAgentProductStateApi.reducer,
        [productPriceChangesApi.reducerPath]: productPriceChangesApi.reducer,
        [supplyProductChangesApi.reducerPath]: supplyProductChangesApi.reducer,
        [productPriceRuleLogEntriesApi.reducerPath]: productPriceRuleLogEntriesApi.reducer,
        [filesApi.reducerPath]: filesApi.reducer
    },
    middleware:
        (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(citiesApi.middleware)
                .concat(waresApi.middleware)
                .concat(productPriceRuleApi.middleware)
                .concat(brandsApi.middleware)
                .concat(suppliesApi.middleware)
                .concat(productPriceApi.middleware)
                .concat(supplyProductApi.middleware)
                .concat(authenticationApi.middleware)
                .concat(salesAgentProductStateApi.middleware)
                .concat(productPriceChangesApi.middleware)
                .concat(supplyProductChangesApi.middleware)
                .concat(productPriceRuleLogEntriesApi.middleware)
                .concat(filesApi.middleware)
})
