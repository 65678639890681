import React, {useEffect, useState} from "react";
import {ICreateOrUpdateProductPriceRule, IProductPriceRule} from "../../models";
import {Error} from "../Error";
import {WareSelector} from "../selectors/WareSelector";
import {BrandSelector} from "../selectors/BrandSelector";
import {useDeleteProductPriceRuleMutation, useUpdateProductPriceRuleMutation} from "../../store";

export interface ProductPriceRuleItemPropertiesProps {
    rule: IProductPriceRule|null
    deleteRule: () => void
    loading: (value: boolean) => void
    closeModal?: () => void
}

export function ProductPriceRuleItemProperties({rule, deleteRule: deleteProductPriceRule, loading, closeModal}: ProductPriceRuleItemPropertiesProps) {
    const [deleteRule, {isLoading: isDeleting, error: deleteError}] = useDeleteProductPriceRuleMutation()
    const [updateRule, {data, isLoading: isUpdating, error: updateError}] = useUpdateProductPriceRuleMutation()

    const [error, setError] = useState('');
    const [isSuccessfully, setSuccessfully] = useState<boolean>(false)
    const [wareIDs, setWareIDs] = useState<number[]>(rule ? rule.wares.map(w => w.id) : [])
    const [cityID, setCityID] = useState<number>(rule ? rule.city.city_id : 0)
    const [brandIDs, setBrandIDs] = useState<number[]>(rule ? rule.brands.map(w => w.id) : [])
    const [witemIDs, setWitemIDs] = useState<string>(rule ? (rule.witem_ids.join(',')) : '')
    const [centiPercentDelta, setCentiPercentDelta] = useState<string|null>((rule && rule.centi_percent_delta) ? ((rule.centi_percent_delta / 100).toString() ?? null) : null)
    const [absoluteDelta, setAbsoluteDelta] = useState<string|null>(rule ? (rule.absolute_delta?.value_in_centi_units ? (rule.absolute_delta?.value_in_centi_units / 100).toString() : null) : null)
    const [price, setPrice] = useState<string|null>(rule ? (rule.price?.value_in_centi_units ? (rule.price?.value_in_centi_units / 100).toString() : null) : null)

    useEffect(() => {
        setWareIDs(rule ? rule.wares.map(w => w.id) : [])
        setCityID(rule ? rule.city.city_id : 0)
        setBrandIDs(rule ? rule.brands.map(w => w.id) : [])
        setWitemIDs(rule ? rule.witem_ids.join(',') : '')
        setCentiPercentDelta((rule && rule.centi_percent_delta) ? ((rule.centi_percent_delta / 100).toString() ?? null) : null)
        setAbsoluteDelta(rule ? (rule.absolute_delta?.value_in_centi_units ? (rule.absolute_delta?.value_in_centi_units / 100).toString() : null) : null)
        setPrice(rule ? (rule.price?.value_in_centi_units ? (rule.price?.value_in_centi_units / 100).toString() : null) : null)
        setError('')
    }, [rule]);
    useEffect(() => {
        setError(JSON.stringify(deleteError) !== '' ? JSON.stringify(deleteError) : error)
        setError(JSON.stringify(updateError) !== '' ? JSON.stringify(updateError) : error)
    }, [deleteError, updateError]);
    useEffect(() => {
        loading(isDeleting || isUpdating)
    }, [isDeleting, isUpdating]);
    useEffect(() => {
        setSuccessfully(false)
    }, [isDeleting, isUpdating, rule?.id])

    function prepareWItemIDs(): number[] {
        if (witemIDs !== '') {
            let stringWItemIDs = witemIDs.split(',');
            return stringWItemIDs.map((witem) => {
                return Number(witem);
            })
        }
        return [];
    }

    const updateRuleHandler = () => {
        setError('');
        if (!rule) {
            setError('Правило не выбрано');
            return;
        }
        let newDataRule: ICreateOrUpdateProductPriceRule = JSON.parse(JSON.stringify({
            id: rule.id,
            ware_ids: wareIDs,
            city_id: cityID,
            brand_ids: brandIDs,
            witem_ids: prepareWItemIDs(),
            centi_percent_delta: centiPercentDelta ? Number(centiPercentDelta.replace(',', '.')) : null,
            absolute_delta: rule.absolute_delta ? rule.absolute_delta.value_in_centi_units / 100 : null,
            price: rule.price ? rule.price.value_in_centi_units / 100 : null
        }))

        let absoluteDeltaNumber = absoluteDelta ? Number(absoluteDelta.replace(',', '.')) : null;
        if (absoluteDeltaNumber !== (rule.absolute_delta?.value_in_centi_units ? rule.absolute_delta?.value_in_centi_units / 100 : null)) {
            newDataRule.absolute_delta = null;
            if (absoluteDeltaNumber) {
                if (Math.sign(absoluteDeltaNumber) === -1) {
                    setError('Значение поля "Абсолютная наценка" должно быть положительным');
                    return;
                }
                newDataRule.absolute_delta = absoluteDeltaNumber;
            }
        }

        let priceNumber = price ? Number(price.replace(',', '.')) : null;
        if (priceNumber !== (rule.price?.value_in_centi_units ? rule.price?.value_in_centi_units / 100 : null)) {
            newDataRule.price = null;
            if (priceNumber) {
                if (Math.sign(priceNumber) === -1) {
                    setError('Значение поля "Цена" должно быть положительным');
                    return;
                }
                newDataRule.price = priceNumber
            }
        }

        updateRule(newDataRule).then((value) => {
            if (value && value.hasOwnProperty('data')) {
                setSuccessfully(true)
            }
        })
    }

    const deleteRuleHandler = () => {
        setError('');
        if (!rule) {
            setError('Правило не выбрано');
            return;
        }
        deleteRule(rule.id)
        deleteProductPriceRule()
        closeModal && closeModal()
    }

    const witemHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.replace(/[^\d,]/g, ' ');
        let index = value.indexOf(' ')
        await setWitemIDs(value.replace(' ', ''))
        if (index !== -1) {
            event.target.setSelectionRange(index,index);
        }
    }

    const centiPercentDeltaHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        (event.target.value.trim().length === 0) ? setCentiPercentDelta(null) : setCentiPercentDelta(event.target.value);
    }

    const setNumberOrNull = (str: string): string|null => {
        if (str.trim().length === 0) {
            return null;
        }
        return str;
    }

    const copyRuleID = () => {
        let textField = document.createElement('textarea')
        textField.innerText = rule ? rule.id : ''
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    };

    const [isAlert, setAlert] = useState(false);
    useEffect(() => {
        setAlert(!!(isSuccessfully || error));
    }, [isSuccessfully, error]);

    return (
        <div
            className="px-4 pt-2 relative pb-1 mb-1 h-full bg-gray-50 min-[1145px]:overflow-y-auto max-[1144px]:bg-white max-[1144px]:mb-5">
            <div className={'absolute top-0 bottom-0'}>
                <span
                    className={"bg-white flex items-center text-black p-3 z-10 break-words rounded drop-shadow-lg sticky top-5 w-full transition-opacity " + (isAlert ? 'visible' : 'hidden')}>
                    <div className={'w-full break-words'}>
                        {isSuccessfully && <p className={'text-center text-green-500'}>Правило успешно обновлено.</p>}
                        {error && <Error error={error}/>}
                    </div>
                    <div className={'px-3'}>
                        <button
                            className={'text-center align-sub'}
                            type={'button'}
                            onClick={() => {
                                setAlert(false)
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                                 stroke="black" strokeWidth="3"
                                 className="stroke-gray-300 hover:stroke-gray-400"><line
                                x1="4" x2="15" y1="4" y2="15"></line><line x1="15" x2="4" y1="4" y2="15"></line></svg>
                        </button>
                    </div>
                </span>
            </div>
            <h2 className={'relative mx-1 mt-2 mb-4 font-medium max-[1144px]:hidden'}>Редактирование правила</h2>
            <div className={'relative mb-4'}>
                <div className={"mb-2 flex justify-between items-center"}>
                    <label htmlFor="url-shortener" className={"text-sm font-bold text-gray-700 block"}>ID
                        правила</label>
                </div>
                <div className={"flex items-center"}>
                    <div className={"relative w-full"}>
                        <input id="url-shortener" type="text" aria-describedby="helper-text-explanation"
                               className={'appearance-none border border-e-0 rounded-s-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'}
                               value={rule ? rule.id : ''} readOnly={true} onChange={() => {
                        }}/>
                    </div>
                    <button data-tooltip-target="tooltip-url-shortener" data-copy-to-clipboard-target="url-shortener"
                            className={"appearance-none flex-shrink-0 z-1 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-500 hover:text-gray-900 bg-gray-100 border border-gray-300 rounded-e-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"}
                            type="button" onClick={() => {
                        copyRuleID()
                    }}>
                        <span id="default-icon"><svg className="w-4 h-4" aria-hidden="true"
                                                     xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                     viewBox="0 0 18 20">
                            <path
                                d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                        </svg></span>

                    </button>
                </div>
            </div>
            <div className="relative mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="city">
                    Город
                </label>
                <input
                    value={rule ? `${rule.city.name} (${rule.city.short_name} ID=${rule.city.city_id})` : ''}
                    onChange={() => {
                    }}
                    readOnly={true}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="city" type="text" placeholder="Город"/>
            </div>
            <div className="relative mb-4">
                <WareSelector
                    onSelect={() => {
                    }}
                    initWareID={wareIDs}
                    isDisabled={false}
                    isClearable={true}
                    onSelectNull={() => {
                        setWareIDs([])
                    }}
                    isMulti={true}
                    onMultiSelect={(wares) => {
                        setWareIDs(wares.map((ware) => {
                            return ware.id
                        }))
                    }}/>
            </div>
            <div className="relative mb-4">
                <BrandSelector
                    onSelect={() => {
                    }}
                    initBrandID={brandIDs}
                    isDisabled={false}
                    isClearable={true}
                    onSelectNull={() => {
                        setBrandIDs([])
                    }}
                    isMulti={true}
                    onMultiSelect={(brands) => {
                        setBrandIDs(brands.map((brand) => {
                            return brand.id
                        }))
                    }}/>
            </div>
            <div className="relative mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="login">
                    Логин
                </label>
                <input
                    value={(rule && rule.user) ? rule.user.login : ''}
                    onChange={() => {
                    }}
                    readOnly={true}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="login" type="text" placeholder="Логин"/>
            </div>
            <div className="relative mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="witemID">
                    Товар
                </label>
                <input
                    value={witemIDs ?? ''}
                    onChange={witemHandler}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="witemID" type="text" placeholder="Товар"/>
            </div>
            <div className="relative mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="centi_percent_delta">
                    Процент наценки
                </label>
                <input
                    value={centiPercentDelta ?? ''}
                    onKeyDown={(e) => {
                        if (['e', '+', '-', '.', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onChange={centiPercentDeltaHandler}
                    className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    id="centi_percent_delta" type="number" placeholder="Процент наценки"/>
            </div>
            <div className="relative mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="absolute_delta">
                    Абсолютная наценка
                </label>
                <input
                    value={absoluteDelta ?? ''}
                    onKeyDown={(e) => {
                        if (['e', '+', '-', '.', ',', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onChange={(e) => (setAbsoluteDelta(setNumberOrNull(e.target.value)))}
                    className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    id="absolute_delta" type="number" placeholder="Абсолютная наценка"/>
            </div>
            <div className="relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
                    Цена
                </label>
                <input
                    value={price ?? ''}
                    onKeyDown={(e) => {
                        if (['e', '+', '-', '.', ',', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onChange={(e) => (setPrice(setNumberOrNull(e.target.value)))}
                    className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    id="price" type="number" placeholder="Цена"/>
            </div>
            {rule && <div className={'mt-2 relative mb-5 text-end text-gray-600'}>
                <p>Дата обновления: <b>{(new Date(rule.update_at)).toLocaleDateString()}</b></p>
            </div>}
            <div className={'mb-2 flex relative ' + (!rule ? 'mt-5' : '')}>
                <button
                    onClick={updateRuleHandler}
                    className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-0.5"
                    type="submit">
                    Сохранить
                </button>
                <button
                    onClick={deleteRuleHandler}
                    className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-0.5"
                    type="submit">
                    Удалить
                </button>
            </div>
        </div>
    )
}
