import {ISupply} from "../../models";
import Select, {createFilter, MultiValue, SingleValue, StylesConfig} from "react-select";
import {Error} from "../Error";
import {Loader} from "../Loader";
import {useGetSuppliesQuery} from "../../store/supplies-api";
import {CustomOption} from "./CustomSelectOption";
import {cutTags} from "./functions";
import {useEffect} from "react";

interface SupplySelectorProps {
    onSelect: (supply: ISupply) => void
    initSupplyID: number | null
    isCustomStyles?: boolean
    isClearable?: boolean
    onSelectNull?: () => void
}

export function SupplySelector(
    {onSelect, initSupplyID, isCustomStyles = true, isClearable = false, onSelectNull = () => {},}: SupplySelectorProps
) {
    const {data: supplies = [], isLoading, error} = useGetSuppliesQuery({});

    const initSupplyIndex = supplies.findIndex((supply: ISupply) => supply.id === initSupplyID)

    useEffect(() => {
        if (!isLoading && supplies.findIndex((supply: ISupply) => supply.id === initSupplyID && supply.is_data_available) === -1) {
            onSelectNull()
        }
    }, [supplies]);

    const onChangeHandler = (newValue: SingleValue<ISupply> | MultiValue<ISupply>) => {
        if (newValue === null) {
            if (isClearable) {
                onSelectNull()
            }
            return
        }
        onSelect(newValue as ISupply)
    }

    const customStyles: StylesConfig<ISupply> = {
        option: (styles) => ( isCustomStyles ? {...styles, cursor: 'pointer'} : {...styles}),
        control: (styles) => ( isCustomStyles ? {...styles, cursor: 'text'} : {...styles}),
        indicatorsContainer: (styles) => ( isCustomStyles ? {...styles, cursor: 'pointer'} : {...styles})
    }

    return (
        <div>
            {error && <Error error={JSON.stringify(error)}/>}
            {supplies && <Select
                isMulti={false}
                isClearable={isClearable}
                isLoading={isLoading}
                placeholder={'Выбор поставщика'}
                options={supplies}
                getOptionLabel={(supply: ISupply) => `${cutTags(supply.name)} (ID=${supply.id})`}
                getOptionValue={(supply: ISupply) => String(supply.id)}
                onChange={onChangeHandler}
                value={supplies[initSupplyIndex]}
                styles={customStyles}
                components={{ Option: CustomOption }}
                classNamePrefix="custom-select"
                filterOption={createFilter({ignoreAccents: false})}
            />}
        </div>
    );
}
