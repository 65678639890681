import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useGetProductPriceChangesQuery} from "../../store/product-price-changes-api";
import {ICity, IWare} from "../../models";
import {Error} from "../Error";
import {Loader} from "../Loader";
import {Pagination} from "../Pagination";
import {CitySelector} from "../selectors/CitySelector";
import {ProductPriceChangesListItem} from "./ProductPriceChangesListItem";
import {WareSelector} from "../selectors/WareSelector";

export function ProductPriceChangesList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState<number>(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
    const [perPage, setPerPage] = useState<number>(Number(searchParams.get("per-page")) ? Number(searchParams.get("per-page")) : 50)
    const [cityID, setCityID] = useState<number|null>(Number(searchParams.get("city-id")) ? Number(searchParams.get("city-id")) : null)
    const [wareID, setWareID] = useState<number|null>(Number(searchParams.get("ware-id")) ? Number(searchParams.get("ware-id")) : null)
    const [userLogin, setUserLogin] = useState<string>(searchParams.get("login") ? String(searchParams.get("login")) : '')
    const [witemID, setWitemID] = useState<string>(searchParams.get("witem-id") ? String(searchParams.get("witem-id")) : '')
    const {data: response, isLoading, error } = useGetProductPriceChangesQuery({page: page, perPage: perPage, cityID: cityID, witemID: (witemID === '' ? null : Number(witemID)), wareID: wareID, login: (userLogin === '' ? null : userLogin) })

    useEffect(() => {
        setPage(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
        setPerPage(Number(searchParams.get("per-page")) ? Number(searchParams.get("per-page")) : 50)
        setWitemID(searchParams.get("witem-id") ? String(searchParams.get("witem-id")) : '')
        setCityID(Number(searchParams.get("city-id")) ? Number(searchParams.get("city-id")) : null)
        setWareID(Number(searchParams.get("ware-id")) ? Number(searchParams.get("ware-id")) : null)
        setUserLogin(searchParams.get("login") ? String(searchParams.get("login")) : '')
    }, [searchParams]);

    const selectPageHandler = (page: number) => {
        searchParams.set('page', String(page))
        setSearchParams(searchParams)
    }
    const selectPerPageHandler = (perPage: number) => {
        searchParams.set('per-page', String(perPage))
        setSearchParams(searchParams)
    }

    const selectCityHandler = (city: ICity|null): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        searchParams.set('city-id', String(city ? city.city_id : null))
        setSearchParams(searchParams)
    }

    const witemHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        searchParams.set('witem-id', String(event.target.value))
        setSearchParams(searchParams)
    }

    const selectWareHandler = (ware: IWare|null): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        searchParams.set('ware-id', String(ware ? ware.id : null))
        setSearchParams(searchParams)
    }

    const userLoginHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        searchParams.set('login', String(event.target.value))
        setSearchParams(searchParams)
    }

    return (
        <div className={'m-5 max-[700px]:m-0'}>
            <div className={'content-center'}>
                <h1 className={'text-2xl'}>Лог изменений цен товаров </h1>
            </div>
            <div className={'min-[1851px]:w-[1600px]'}>
                <div className={'mt-10'}>
                    <CitySelector onSelect={selectCityHandler} initCityID={cityID} isDisabled={false} isClearable={true} onSelectNull={() => {selectCityHandler(null)}} />
                </div>
                <div className={'mt-2'}>
                    <WareSelector onSelect={selectWareHandler} initWareID={wareID} isDisabled={false} isClearable={true} onSelectNull={() => {selectWareHandler(null)}} />
                </div>
                <div className={'mt-2'}>
                    <input
                        placeholder={'ID товара'}
                        onChange={witemHandler}
                        className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                        type={'number'} value={witemID ?? ''}/>
                </div>
                <div className={'mt-2'}>
                    <input
                        placeholder={'Логин'}
                        onChange={userLoginHandler}
                        className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                        type={'text'} value={userLogin ?? ''}/>
                </div>
                <div>
                    {error && <Error error={JSON.stringify(error)}/>}
                    {isLoading && <Loader/>}
                    <Pagination
                        initPage={page}
                        initPageSize={perPage}
                        allCount={response ? response.all_count : 0}
                        onSelectPageHandler={selectPageHandler}
                        onSelectPageSizeHandler={selectPerPageHandler}/>
                </div>
                <table className={'table-auto mt-5 w-[1600px] border-collapse max-[1850px]:w-full'}>
                    <thead className={'bg-blue-200 max-[950px]:hidden'}>
                        <tr>
                            <th className={'border border-gray-200 '}>
                                Город
                            </th>
                            <th className={'border border-gray-200'}>
                                Товар
                            </th>
                            <th className={'border border-gray-200'}>
                                Пользователь
                            </th>
                            <th className={'border border-gray-200'}>
                                Дата
                            </th>
                            <th className={'border border-gray-200'}>
                                Предыдущее состояние
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {response && response.product_price_changes.map(product_price_change =>
                                <ProductPriceChangesListItem productPriceChange={product_price_change} key={product_price_change.id} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
