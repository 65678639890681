import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {prepareHeadersHandler, responseHandler} from "./functions";
import {IProductPriceChange} from "../models";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

interface ProductPriceChangesListResponse {
    product_price_changes: IProductPriceChange[],
    all_count: number
}

export const productPriceChangesApi = createApi({
    reducerPath: 'productPriceChangesApi',
    tagTypes: ['ProductPriceChanges'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/product-price-change',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getProductPriceChanges: builder.query<ProductPriceChangesListResponse, { page: number, perPage: number, cityID: number|null, witemID: number|null, wareID: number|null, login: string|null}>({
            query: (arg) => ({
                url: 'list',
                method: 'POST',
                body: {
                    "page": arg.page,
                    "per_page": arg.perPage,
                    "city_id": arg.cityID,
                    "witem_id": arg.witemID,
                    "ware_id": arg.wareID,
                    "user_login": arg.login,
                },
            }),
            transformErrorResponse(baseQueryReturnValue: BaseQueryError<any>): string {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.product_price_changes.map(({id}) => ({type: 'ProductPriceChanges' as const, id})),
                    {type: 'ProductPriceChanges', id: 'LIST'},
                ]
                : [{type: 'ProductPriceChanges', id: 'LIST'}],
        }),
    })
})

export const {
    useGetProductPriceChangesQuery
} = productPriceChangesApi
