import {IBrand, ICity, IProductPriceRule, IWare} from "../../models";
import React from "react";
import {CitySelector} from "../selectors/CitySelector";
import {WareSelector} from "../selectors/WareSelector";
import {BrandSelector} from "../selectors/BrandSelector";
import {cutTags} from "../selectors/functions";

export interface ProductPriceRuleItemProps {
    rule: IProductPriceRule
    selectRule: (rule: IProductPriceRule|null) => void
    style: string
}

export function ProductPriceRuleItem({rule, selectRule, style}: ProductPriceRuleItemProps) {
    const citySelectHandler = (city: ICity) => {
        console.log(city)
    }

    const wareSelectHandler = (ware: IWare[]) => {
        console.log(ware)
    }

    const brandSelectHandler = (brand: IBrand[]) => {
        console.log(brand)
    }

    const prepareWaresData = (wares: IWare[]): string[] => {
        return wares.map((ware) => {
            return cutTags(ware.name) + ' (ID: ' + ware.id + ')' + (!ware.is_active_laptop ? ' (не акт. в LP)' : '')
        })
    }

    const prepareBrandsData = (brands: IBrand[]): string[] => {
        return brands.map((brand) => {
            return brand.name + ' (ID: ' + brand.id + ')'
        })
    }

    return (
        <tr key={rule.id} className={style + ' cursor-pointer'} onClick={() => {
            selectRule(rule)
        }}>
            <td className={'p-3 break-words border border-gray-50 border-b-gray-200 max-[650px]:hidden'}>
                {`${rule.city.name} (${rule.city.short_name} ID=${rule.city.city_id})`}
            </td>
            <td className={'p-3 break-words border border-gray-50 border-b-gray-200 max-[650px]:hidden'}>
                {prepareWaresData(rule.wares).join(',\n')}
            </td>
            <td className={'p-3 break-words border border-gray-50 border-b-gray-200 max-[650px]:hidden'}>
                {prepareBrandsData(rule.brands).join(',\n')}
            </td>
            <td className={'p-3 break-words border border-gray-50 border-b-gray-200 max-[650px]:hidden'}>
                {rule.witem_ids.join(', ')}
            </td>
            <td className={'p-3 border border-gray-50 border-b-gray-200 border-r-0 max-[650px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={true}
                    className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                    type={'text'} value={(new Date(rule.update_at)).toLocaleDateString()}/>
            </td>
            <td className={'p-3 border break-words border-b-gray-300 border-r-0 border-l-0 min-[651px]:hidden'}>
                <div>
                    <b>Город:</b> {`${rule.city.name} (${rule.city.short_name} ID=${rule.city.city_id})`}
                </div>
                {rule.wares.length !== 0 && <div>
                    <b>Каталог:</b> {prepareWaresData(rule.wares).join(',\n')}
                </div>}
                {rule.brands.length !== 0 && <div>
                    <b>Бренд:</b> {prepareBrandsData(rule.brands).join(',\n')}
                </div>}
                {rule.witem_ids.length !== 0 && <div>
                    <b>Товар:</b> {rule.witem_ids.join(', ')}
                </div>}
                <div className={'mt-2  text-gray-600'}>
                    <p>Дата обновления: <b>{(new Date(rule.update_at)).toLocaleDateString()}</b></p>
                </div>
            </td>
        </tr>
    )
}
