import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {ICalculatedAndCurrentProductPrice, ISetProductPrice} from "../models";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {prepareHeadersHandler, responseHandler} from "./functions";

export interface GetCalculatedAndCurrentProductPriceListResponse {
    calculated_and_current_product_prices: ICalculatedAndCurrentProductPrice[]
    page: number
    per_page: number
    all_count: number
    total_page: number
}

export const productPriceApi = createApi({
    reducerPath: 'productPriceApi',
    tagTypes: ['ProductPrices'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/product-price',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getProductPrices: builder.query<GetCalculatedAndCurrentProductPriceListResponse, { page: number, perPage: number, cityID: number, orderBy: string, isAsc: boolean, witemID: number|null, brandID: number|null, wareID: number|null }>({
            query: (arg) => ({
                url: 'list',
                method: 'POST',
                body: {
                    "page": arg.page,
                    "per_page": arg.perPage,
                    "city_id": arg.cityID,
                    "order_by": arg.orderBy,
                    "is_asc": arg.isAsc,
                    "witem_id": arg.witemID,
                    "brand_id": arg.brandID,
                    "ware_id": arg.wareID
                },
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.calculated_and_current_product_prices.map(({witem_id}) => ({type: 'ProductPrices' as const, witem_id})),
                    {type: 'ProductPrices', id: 'LIST'},
                ]
                : [{type: 'ProductPrices', id: 'LIST'}],
        }),
        createProductPrices: builder.mutation<ISetProductPrice[], any>({
            query: (productPrices: ISetProductPrice[]): any => ({
                url: 'create',
                method: 'POST',
                body: {
                    prices: productPrices
                }
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            invalidatesTags: [{type: 'ProductPrices', id: 'LIST'}]
        }),
    })
})

export const {
    useGetProductPricesQuery,
    useCreateProductPricesMutation
} = productPriceApi
