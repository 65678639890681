import {NavigationLink, NavigationLinkProps} from "./NavigationLink";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthContext";

export function Navigation() {

    const navigationLinks: NavigationLinkProps[] = [
        {
            name: 'Дом',
            link: '/'
        },
        {
            name: 'Товары торговых агентов',
            link: '/sales-agent'
        },
        {
            name: 'Правила наценок товаров',
            link: '/product-price-rule'
        },
        {
            name: 'Наценки товаров',
            link: '/product-price'
        },
        {
            name: 'Информация API поставщиков и наши данные',
            link: '/supply-external-and-supply-product-list'
        },
        {
            name: ['Цены товаров', 'Цены поставщиков', 'Правила наценок'],
            link: ['/product-price-changes-list', '/supply-product-changes-list', '/product-price-rule-log-entries-list'],
            listName: 'Лог изменений',
            listCover: React.createElement(
                'svg',
                {key: 'log-logo', className: 'w-4 h-6 text-gray-800 dark:text-white', "aria-hidden": "true", "xmlns": "http://www.w3.org/2000/svg", "fill":"none", "viewBox":"0 0 24 24"},
                [
                    React.createElement('path', {"key":"log-logo-src", "stroke": "currentColor", "strokeLinecap": "round", "strokeLinejoin": "round", "strokeWidth": "2", "d": "M18 5V4a1 1 0 0 0-1-1H8.914a1 1 0 0 0-.707.293L4.293 7.207A1 1 0 0 0 4 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5M9 3v4a1 1 0 0 1-1 1H4m11.383.772 2.745 2.746m1.215-3.906a2.089 2.089 0 0 1 0 2.953l-6.65 6.646L9 17.95l.739-3.692 6.646-6.646a2.087 2.087 0 0 1 2.958 0Z"})
                ])
        }
    ]

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [location, setLocation] = useState(window.location.pathname);

    const linkClickHandler = (selectedLink: string) => {
        setLocation(selectedLink)
        setIsMenuOpen(false);
    }

    const getLinkStyle = (selectedLink: string): string => {
        if (selectedLink === location) {
            return ' bg-gray-100 dark:bg-gray-600'
        }
        return ''
    }

    const isListSelected = (links: string|string[]): boolean => {
        if (Array.isArray(links)) {
            return links.includes(location)
        }
        return false
    }

    const handleLogOut = () => {
        localStorage.removeItem('user');
        setCurrentUser(null);
    };

    const [isMini, setMini] = useState<boolean>(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            let width = document.body.clientWidth;
            if (width <= 900) {
                setMini(true);
                setIsMenuOpen(false);
            } else {
                setMini(false);
            }

        };
        handleResize()
        window.addEventListener('resize', handleResize, { passive: true });
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getArrow = (color: string = 'text-gray-800') => {
        if (!isMenuOpen) {
            return (
                <span className="ml-2"><svg className={'w-3 h-6 dark:text-white ' + color} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"></path>
                </svg></span>
            )
        } else {
            return (
                <span className="ml-2"><svg className={'w-3 h-6 dark:text-white ' + color} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"></path>
                </svg></span>
            )
        }
    }

    return (
        <aside className={'w-60px pl-3 py-4 bg-gray-50 dark:bg-gray-800 ' + ((isMini && !isMenuOpen) ? 'h-[72px]': 'h-screen')}>
            <div className={'flex justify-between transition-all'}>
                <h1 className={'flex items-center p-2 text-base font-normal text-orange-600 rounded-lg'}>
                    Evo Admin</h1>
                <button onClick={(e) => setIsMenuOpen(!isMenuOpen)} className={'flex items-center p-2 mr-5 text-base font-normal text-orange-600 rounded-lg  min-[901px]:hidden ' + (isMini? 'inline' : 'hidden')}>
                    {getArrow()}</button>
            </div>
            <div
                className={"w-full flex-col z-10 min-w-60 " + ((isMini && !isMenuOpen) ? 'hidden': 'inline')}>
                <nav className="space-y-2 mt-10 h-[calc(100vh-8rem)] overflow-y-auto custom-scrollbar">
                    <a href={'http://admin.loc.laptop.ru/manage'}
                       className={'flex items-center p-2 mr-0 text-base font-normal text-gray-900 rounded-none rounded-l-lg max-[900px]:mr-3 max-[900px]:rounded-r-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700'}
                    >
                        Старая админка
                    </a>
                    {navigationLinks.map((linkItem, index) => {
                        return (<NavigationLink link={linkItem.link} name={linkItem.name}
                                                linkStyle={getLinkStyle} linkClickHandler={linkClickHandler}
                                                isListSelected={isListSelected(linkItem.link)}
                                                listName={linkItem.listName}
                                                listCover={linkItem.listCover} key={index}/>)
                    })}
                </nav>
                <div className={''}>
                    <button
                        type='button'
                        className={'p-2 text-xs font-normal text-gray-400 rounded-lg dark:text-slate-500 hover:bg-gray-200 dark:hover:bg-gray-700'}
                        onClick={handleLogOut}>
                        Выйти
                    </button>
                </div>
            </div>
        </aside>
)
}
