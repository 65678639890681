import React, {useEffect, useState} from "react";
import {ICalculatedAndCurrentProductPrice, IMoney, ISetProductPrice} from "../../models";

export interface ProductPriceListItemProps {
    witem: ICalculatedAndCurrentProductPrice,
    cityID: number
    addProductPrice: (witemId: number, productPrice: ISetProductPrice|null) => void
}

export function ProductPriceListItem({witem, cityID, addProductPrice}: ProductPriceListItemProps) {
    const [localProductPrice, setLocalProductPrice] = useState<string>(witem.product_price ? String(witem.product_price.price_in_centi_units / 100) : '')

    useEffect(() => {
        setLocalProductPrice(witem.product_price ? String(witem.product_price.price_in_centi_units / 100) : '')
    }, [witem.product_price]);

    const localProductPriceHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalProductPrice(event.target.value)
        createLocalProduct(event.target.value)
    }

    const createLocalProduct = (price: string|null) => {
        if (price === '') {
            addProductPrice(witem.witem_id, null)
            return
        }
        let localPrice = witem.calculated_product_price.value_in_centi_units
        let isPriceManual = false
        if (price !== null) {
            localPrice = Math.round(Number(price) * 100)
            isPriceManual = true
        } else {
            setLocalProductPrice(String(localPrice / 100))
        }
        let productToUpdate = {
            witem_id: witem.witem_id,
            city_id: cityID,
            price: {
                value_in_centi_units: localPrice,
                currency: witem.calculated_product_price.currency
            } as IMoney,
            is_price_manual: isPriceManual
        } as ISetProductPrice

        addProductPrice(witem.witem_id, productToUpdate)
    }

    const getProductPriceRuleMarginValue = (ruleText: string): string => {
        let delta = '';

        if (witem.product_price_rule?.price) {
            return 'Фиксированная цена' +  ruleText + (witem.product_price_rule?.price.value_in_centi_units / 100).toFixed(2).replace('.', ',') + ' ' + witem.product_price_rule?.price.currency
        }
        if (witem.product_price_rule?.absolute_delta) {
            delta += (witem.product_price_rule?.absolute_delta.value_in_centi_units / 100).toFixed(2).replace('.', ',') + ' ' + witem.product_price_rule?.absolute_delta.currency
        }
        if (witem.product_price_rule?.centi_percent_delta) {
            if (delta !== '') {
                delta += ' и '
            }
            delta += (witem.product_price_rule?.centi_percent_delta / 100).toFixed(2).replace('.', ',') + '%'
        }

        return delta !== '' ? 'Наценка' + ruleText + delta : 'Для правила наценки не выставлено значение наценки'
    }

    const getProductPriceRuleText = (): string => {
        if (witem.product_price_rule) {
            if (witem.product_price_rule.witem_ids.length > 0) {
                return getProductPriceRuleMarginValue(' на товар - ')
            }
            if (witem.product_price_rule.brands.length > 0) {
                return getProductPriceRuleMarginValue(' на бренд - ')
            }
            if (witem.product_price_rule.wares.length > 0) {
                return getProductPriceRuleMarginValue(' на каталог - ')
            }
            if (witem.product_price_rule.city) {
                return getProductPriceRuleMarginValue(' на город - ')
            }
        }
        return 'Правила наценки ещё нет'
    }

    const getEntryPrice = () => {
        return 'Вход - ' + (witem.best_supply_product_price.value_in_centi_units / 100).toFixed(2).replace('.', ',') + ' ' + witem.best_supply_product_price.currency
    }

    const getLeftColor = ():string => {
        let color = ' text-sky-400'
        if (witem.product_price) {
            color = ' text-amber-600'
        }
        return color
    }

    const getRightsColor = ():string => {
        if (witem.product_price) {
            if (witem.product_price.is_price_manual) {
                return ' text-green-500'
            }
            if (witem.product_price.price_in_centi_units === witem.calculated_product_price.value_in_centi_units) {
                return ' text-amber-600'
            } else {
                return ' text-red-600'
            }
        }
        return ''
    }

    const getProductMargin = ():string => {
        if (localProductPrice) {
            return (Number(localProductPrice) - witem.best_supply_product_price.value_in_centi_units / 100).toFixed(2).replace('.', ',')
        }
        return (witem.calculated_product_price.value_in_centi_units / 100 - witem.best_supply_product_price.value_in_centi_units / 100).toFixed(2).replace('.', ',')
    }

    return (
        <tr className={'border-b-2'}>
            <td className={'p-3 max-[1617px]:hidden'}>
                {witem.witem_id}
            </td>
            <td className={'p-3 break-words max-[1617px]:hidden'}>
                {witem.witem_name}
            </td>
            <td className={'p-3 group relative w-max max-[1617px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={false}
                    className={'appearance-none text-center rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}
                    type={'number'} value={witem.calculated_product_price.value_in_centi_units / 100}/>
                <span
                    className="bg-white text-black p-3 rounded drop-shadow-lg pointer-events-none absolute -top-7 left-0 w-max opacity-0 transition-opacity group-hover:opacity-100">
                    {getEntryPrice()}<br/>
                    {getProductPriceRuleText()}
                </span>
            </td>
            <td className={'p-3 max-[1617px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={true}
                    className={'appearance-none text-center bg-white rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}
                    type={'text'} value={witem.calculated_product_price.currency}/>
            </td>
            <td className={'max-[1617px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={true}
                    className={'appearance-none text-center bg-white rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}
                    type={'text'} value={getProductMargin()}/>
            </td>
            <td className={'p-3 relative max-[1617px]:hidden'}>
                {witem.product_price_rule ?
                    <div className={'w-5 h-5 bg-green-500 rounded-full absolute left-1/3 top-1/3 ml-2.5 mt-1'}></div> :
                    <div className={'w-5 h-5 bg-red-500 rounded-full absolute left-1/3 top-1/3 ml-2.5 mt-1'}></div>
                }
            </td>
            <td className={'p-3 items-center max-[1617px]:hidden'}>
                <button disabled={false} onClick={() => {
                    createLocalProduct(null)
                }} className={'w-full p-3 ml-5 text-black'}>
                    <svg className="w-6 h-6 text-gray-800" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"></path>
                    </svg>
                </button>
            </td>
            <td className={'p-3 max-[1617px]:hidden'}>
                <input
                    onChange={localProductPriceHandler}
                    className={'appearance-none border rounded w-full py-2 pl-3 pr-1 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                    type={'number'} value={localProductPrice ?? ''}/>
            </td>
            <td className={'max-[1617px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={true}
                    className={'appearance-none text-center bg-white rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                    type={'text'} value={witem.product_price ? witem.product_price.currency : ''}/>
            </td>
            <td className={'max-[1617px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={true}
                    className={'appearance-none text-center bg-white rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                    type={'text'}
                    value={witem.product_price ? (new Date(witem.product_price.updated_at)).toLocaleDateString() : ''}/>
            </td>
            <td className={'px-3 py-3 w-full border-2 break-words border-b-gray-300 border-t-gray-300 border-r-0 border-l-0 min-[1618px]:hidden max-[700px]:px-0'}>
                <div className={'flex flex-wrap'}>
                    <div className={'mr-5'}>
                        <span className={'text-gray-600'}>{witem.witem_id}</span>
                    </div>
                    <div className={'break-words break-all text-gray-600'}>
                        <b className={'break-words'}>{witem.witem_name}</b>
                    </div>
                </div>
                <div className={'flex justify-center border border-l-0 border-r-0 border-b-0 border-gray-100 max-[700px]:flex-col'}>
                    <div
                        className={'flex-col w-full p-1 border border-l-0 border-t-0 border-b-0 border-r-1 border-gray-100 max-[700px]:border-r-0'}>
                        <div className={'flex flex-wrap w-full max-[700px]:ml-0'}>
                            <div className={'mr-5 break-words relative'}>
                                <b className={'mr-3'}>Правило наценки:</b>
                                <div
                                    className={'w-5 h-5 bg-green-500 rounded-full absolute -right-5 -top-0.5 ml-2.5 mt-1 mr-2 ' + (witem.product_price_rule ? 'bg-green-500' : 'bg-red-500')}></div>
                            </div>
                            <div className={'group relative mr-5'}>
                                <b>Расчётная цена:</b>
                                <span
                                    className={'appearance-none max-w-min text-center rounded py-2 pl-3 pr-1 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}>
                                    {(witem.calculated_product_price.value_in_centi_units / 100).toString().replace('.', ',')}
                                </span>
                                <span
                                    className="bg-white text-black p-3 z-10 break-words rounded drop-shadow-lg pointer-events-none absolute -top-16 left-0 right-0 w-full opacity-0 transition-opacity group-hover:opacity-100">
                                    <div className={'w-full  break-words'}>{getEntryPrice()}<br/>
                                        {getProductPriceRuleText()}</div>
                                </span>
                                <span
                                    className={'appearance-none text-center bg-white rounded w-full py-0 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}>
                                    {witem.calculated_product_price.currency}
                                </span>
                            </div>
                            <div className={'break-words mr-5'}>
                                <b>Маржа:</b>
                                <span
                                    className={'appearance-none max-w-min text-center rounded py-2 pl-3 pr-1 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}>
                                {getProductMargin()}
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className={'w-full text-center min-[701px]:hidden'}>
                        <button disabled={false} onClick={() => {
                            createLocalProduct(null)
                        }}
                                className={'w-full border border-gray-100 border-l-0 border-r-0 px-3 py-1.5 font-bold hover:bg-gray-200 hover:border-gray-200 text-black'}>
                            Перенести
                        </button>
                    </div>
                    <div className={'flex  flex-wrap content-between w-full p-1'}>
                        {(witem.product_price || localProductPrice) &&
                            <div className={'flex flex-wrap  max-[700px]:ml-0'}>
                                <div className={'flex items-center h-full mr-3'}>
                                    <div className={'min-w-[120px]'}><b>Текущая цена:</b></div>
                                    <div>
                                        <input
                                            onChange={localProductPriceHandler}
                                            className={'appearance-none border rounded w-full min-w-[100px] max-w-[160px] max-[350px]:pl-0 text-center py-0 pl-2 pr-0 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                                            type={'number'} value={localProductPrice ?? ''}/>
                                    </div>
                                    <span
                                        className={'appearance-none max-w-min min-w-[45px] text-center bg-white rounded py-0 pl-3 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}>
                                        {witem.product_price ? witem.product_price.currency : ''}
                                    </span>
                                </div>
                            </div>}
                        {witem.product_price && <div className={'h-fit w-fit'}>
                            <div className={'text-gray-600 w-fit'}>
                                <p className={'w-fit'}>Дата обновления: <b
                                    className={getRightsColor()}>{witem.product_price ? (new Date(witem.product_price.updated_at)).toLocaleDateString() : ''}</b>
                                </p>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className={'w-full text-center max-[700px]:hidden'}>
                    <button disabled={false} onClick={() => {
                        createLocalProduct(null)
                    }}
                            className={'w-full border border-gray-100 border-l-0 border-r-0 px-3 py-1.5 font-bold hover:bg-gray-200 hover:border-gray-200 text-black'}>
                        Перенести
                    </button>
                </div>



            </td>
        </tr>
    )
}
