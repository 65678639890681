import {ICreateOrUpdateSupplyProduct, IMoney, ISupplyExternalWItemSupplyProduct} from "../../models";
import React, {useEffect, useState} from "react";

export interface SupplyExternalAndSupplyProductListItemProps {
    witem: ISupplyExternalWItemSupplyProduct,
    isTransferBlocked: boolean
    addSupplyProduct: (witemId: number, supplyProduct: ICreateOrUpdateSupplyProduct|null) => void
}

export function SupplyExternalAndSupplyProductListItem({witem, isTransferBlocked, addSupplyProduct}: SupplyExternalAndSupplyProductListItemProps) {
    const [localSupplyProductPrice, setLocalSupplyProductPrice] = useState<string>(witem.supply_product ? String(witem.supply_product.price.value_in_centi_units / 100) : '')
    const [localSupplyProductStocks, setLocalSupplyProductStocks] = useState<string>(witem.supply_product ? String(witem.supply_product.stocks) : '')

    const localExternalWItemPrice = (witem.supply_external_witem && witem.supply_external_witem.prices.prices.length > 0) ? witem.supply_external_witem?.prices.prices[0].value : null
    const localExternalWItemStocks = (witem.supply_external_witem && witem.supply_external_witem.in_stock.stocks.length > 0) ? witem.supply_external_witem?.in_stock.stocks[0].value : ''
    const localExternalWItemConvertedStocks = (witem.supply_external_witem && witem.supply_external_witem.in_stock.stocks.length > 0) ? witem.supply_external_witem?.in_stock.stocks[0].numeric_value : 0;

    useEffect(() => {
        setLocalSupplyProductPrice(witem.supply_product ? String(witem.supply_product.price.value_in_centi_units / 100) : '')
        setLocalSupplyProductStocks(witem.supply_product ? String(witem.supply_product.stocks) : '')
    }, [witem.supply_product]);

    const localSupplyProductPriceHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalSupplyProductPrice(event.target.value)
        createLocalSupplyProduct(event.target.value, localSupplyProductStocks)
    }

    const localSupplyProductStocksHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalSupplyProductStocks(event.target.value)
        createLocalSupplyProduct(localSupplyProductPrice, event.target.value)
    }

    const createLocalSupplyProduct = (price: string|null, stocks: string|null) => {
        if (price === '' || stocks === '') {
            addSupplyProduct(witem.witem_id, null)
            return
        }
        let localPrice = isTransferBlocked ? 0 : (localExternalWItemPrice ? localExternalWItemPrice.value_in_centi_units as number : 0)
        let localStocks = isTransferBlocked ? 0 : Number(localExternalWItemConvertedStocks)
        let isPriceManual = false
        let isStocksManual = false
        if (price !== null) {
            localPrice = Math.round(Number(price) * 100)
            isPriceManual = true
        } else {
            setLocalSupplyProductPrice(String(localPrice / 100))
        }
        if (stocks !== null) {
            localStocks = Number(stocks)
            isStocksManual = true
        } else {
            setLocalSupplyProductStocks(String(localStocks))
        }

        let supplyProductToUpdate = {
            supply_code: isTransferBlocked ? witem.supply_product?.supply_code as string : witem.supply_external_witem?.supply_code as string,
            supply_id: isTransferBlocked ? witem.supply_product?.supply.id as number : witem.supply_external_witem?.supply_id as number,
            price: {
                value_in_centi_units: localPrice,
                currency: isTransferBlocked ? witem.supply_product?.price.currency as string : (localExternalWItemPrice ? localExternalWItemPrice.currency as string : 'RUB')
            },
            stocks: localStocks,
            is_price_manual: isPriceManual,
            is_stocks_manual: isStocksManual,
            witem_id: witem.witem_id
        } as ICreateOrUpdateSupplyProduct

        addSupplyProduct(witem.witem_id, supplyProductToUpdate)
    }

    const getLeftColor = ():string => {
        let color = ' text-sky-400'
        if (witem.supply_product) {
            color = ' text-amber-600'
        }
        return color
    }

    const getRightsColor = ():string => {
        if (witem.supply_product && witem.supply_external_witem) {
            if (witem.supply_product.is_stocks_manual || witem.supply_product.is_stocks_manual) {
                return ' text-green-500'
            }
            if (witem.supply_product.price.value_in_centi_units === localExternalWItemPrice?.value_in_centi_units
                && witem.supply_product.stocks === Number((localExternalWItemStocks as string).match(/\d+/))) {
                return ' text-amber-600'
            } else {
                let color = ' text-gray-700'
                let supplyExternalWitemAt = new Date(witem.supply_external_witem.updated_at)
                let supplyProductAt = new Date(witem.supply_product.updated_at)
                if (Math.abs(supplyExternalWitemAt.valueOf() - supplyProductAt.valueOf()) >= 604800000) {
                    color = ' text-red-600'
                }
                return color
            }
        }
        return ''
    }

    return (
        <tr className={'border-b-2'}>
            <td className={'p-3 max-[1590px]:hidden'}>
                {witem.witem_id}
            </td>
            <td className={'p-3 break-words max-w-[200px] max-[1590px]:hidden'}>
                {witem.witem_name}
            </td>
            <td className={'py-3 max-[1590px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={false}
                    className={'appearance-none text-center rounded w-full py-2 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}
                    type={'number'}
                    value={witem.supply_external_witem && localExternalWItemPrice ? localExternalWItemPrice?.value_in_centi_units / 100 : ''}/>
            </td>
            <td className={'p-3 max-[1590px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={false}
                    className={'appearance-none text-center rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}
                    type={'text'} value={localExternalWItemPrice?.currency}/>
            </td>
            <td className={'py-3 max-[1590px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={false}
                    className={'appearance-none text-center rounded w-full py-2 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}
                    type={'text'} value={localExternalWItemStocks}/>
            </td>
            <td className={'py-3 max-[1590px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={false}
                    className={'appearance-none text-center rounded w-full py-2 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}
                    type={'text'} value={localExternalWItemConvertedStocks}/>
            </td>
            <td className={'p-3 max-[1590px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={false}
                    className={'appearance-none text-center rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}
                    type={'text'}
                    value={witem.supply_external_witem ? (new Date(witem.supply_external_witem.updated_at)).toLocaleDateString() : ''}/>
            </td>
            <td className={'p-3 max-[1590px]:hidden'}>
                <button disabled={isTransferBlocked} onClick={() => {
                    createLocalSupplyProduct(null, null)
                }} className={'w-full p-3 flex justify-center ' + (isTransferBlocked ? 'text-gray-500' : 'text-black')}>
                    <svg className="w-6 h-6 text-gray-800" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"></path>
                    </svg>
                </button>
            </td>
            <td className={'py-3 max-[1590px]:hidden'}>
                <input
                    onChange={localSupplyProductPriceHandler}
                    className={'appearance-none border rounded w-full py-2 pl-3 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                    type={'number'} value={localSupplyProductPrice ?? ''}/>
            </td>
            <td className={'max-[1590px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={false}
                    className={'appearance-none text-center rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                    type={'text'} value={witem.supply_product ? witem.supply_product.price.currency : ''}/>
            </td>
            <td className={'py-3 max-[1590px]:hidden'}>
                <input
                    onChange={localSupplyProductStocksHandler}
                    className={'appearance-none border rounded w-full py-2 pl-3 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                    type={'number'} value={localSupplyProductStocks ?? ''}/>
            </td>
            <td className={'max-[1590px]:hidden'}>
                <input
                    readOnly={true}
                    disabled={false}
                    className={'appearance-none text-center rounded w-full py-2 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                    type={'text'}
                    value={witem.supply_product ? (new Date(witem.supply_product.updated_at)).toLocaleDateString() : ''}/>
            </td>
            <td className={'px-3 py-3 w-full border-2 break-words border-b-gray-300 border-t-gray-300 border-r-0 border-l-0 min-[1591px]:hidden max-[700px]:px-0'}>
                <div className={'flex flex-wrap'}>
                    <div className={'mr-5'}>
                        <span className={'text-gray-600'}>{witem.witem_id}</span>
                    </div>
                    <div className={'break-words break-all text-gray-600'}>
                        <b>{witem.witem_name}</b>
                    </div>
                </div>
                <div className={'flex justify-center border border-l-0 border-r-0 border-b-0 border-gray-100 max-[700px]:flex-col'}>
                    <div
                        className={'flex-col w-full p-1 border border-l-0 border-t-0 border-b-0 border-r-1 border-gray-100 max-[700px]:border-r-0'}>
                        <b>API поставщика:</b>
                        {witem.supply_external_witem && <div className={'flex flex-wrap w-full ml-5'}>
                            <div className={'mr-5'}>
                                <b>Цена:</b>
                                <span
                                    className={'appearance-none max-w-min text-center rounded py-2 pl-3 pr-1 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}>
                                    {(localExternalWItemPrice ? localExternalWItemPrice?.value_in_centi_units / 100 : '').toString().replace('.', ',')}
                                </span>
                                <span
                                    className={'appearance-none text-center bg-white rounded w-full py-0 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}>
                                    {localExternalWItemPrice?.currency}
                                </span>
                            </div>
                            <div className={'break-words mr-5'}>
                                <b>Оригинальный остаток:</b>
                                <span
                                    className={'appearance-none max-w-min text-center rounded py-2 pl-3 pr-1 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}>
                                    {localExternalWItemStocks}
                                </span>
                            </div>
                            <div className={'break-words mr-5'}>
                                <b>Числовой остаток:</b>
                                <span
                                    className={'appearance-none max-w-min text-center rounded py-2 pl-3 pr-1 leading-tight focus:outline-none focus:shadow-outline' + getLeftColor()}>
                                    {localExternalWItemConvertedStocks}
                                </span>
                            </div>
                        </div>}
                        <div className={'min-[701px]:hidden'}>{witem.supply_external_witem &&
                            <div className={'w-full h-fit'}>
                                <div className={'text-gray-600'}>
                                    <p>Дата обновления: <b
                                        className={getLeftColor()}>{(new Date(witem.supply_external_witem.updated_at)).toLocaleDateString()}</b>
                                    </p>
                                </div>
                            </div>}</div>
                    </div>
                    <div className={'w-full text-center min-[701px]:hidden'}>
                        <button disabled={isTransferBlocked} onClick={() => {
                            createLocalSupplyProduct(null, null)
                        }}
                                className={'w-full border border-l-0 border-r-0 border-gray-100 px-3 py-1.5 font-bold hover:bg-gray-200 hover:border-gray-200 ' + (isTransferBlocked ? 'text-gray-500' : 'text-black')}>
                            Перенести
                        </button>
                    </div>
                    <div className={'flex-col w-full p-1'}>
                        <b>Наши данные по поставщику:</b>
                        {(witem.supply_product || localSupplyProductPrice !== '' || localSupplyProductStocks !== '') &&
                            <div className={'flex flex-wrap w-full ml-5'}>
                                <div className={'flex items-center h-full mr-3'}>
                                    <div className={'min-w-[50px]'}><b>Цена:</b></div>
                                    <div>
                                        <input
                                            onChange={localSupplyProductPriceHandler}
                                            className={'appearance-none border rounded w-full min-w-[120px] max-w-[160px] text-center py-0 pl-2 pr-0 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                                            type={'number'} value={localSupplyProductPrice ?? ''}/>
                                    </div>
                                    <span
                                        className={'appearance-none max-w-min min-w-[45px] text-center bg-white rounded py-0 pl-3 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}>
                                        {witem.supply_product ? witem.supply_product.price.currency : ''}
                                    </span>
                                </div>
                                <div className={'flex items-center h-full mr-3'}>
                                    <div className={'min-w-[75px]'}><b>Остатки:</b></div>
                                    <div>
                                        <input
                                            onChange={localSupplyProductStocksHandler}
                                            className={'appearance-none border rounded w-full min-w-[80px] max-w-[135px] text-center py-0 pl-2 pr-0 leading-tight focus:outline-none focus:shadow-outline' + getRightsColor()}
                                            type={'number'} value={localSupplyProductStocks ?? ''}/>
                                    </div>
                                </div>
                            </div>}
                        <div className={'min-[701px]:hidden'}>{witem.supply_product && <div className={'w-full h-fit'}>
                            <div className={'text-gray-600'}>
                                <p>Дата обновления: <b
                                    className={getRightsColor()}>{(new Date(witem.supply_product.updated_at)).toLocaleDateString()}</b>
                                </p>
                            </div>
                        </div>}</div>
                    </div>
                </div>
                <div className={'flex w-full max-[700px]:hidden'}>
                    <div className={'w-full border border-l-0 border-t-0 border-b-0 border-gray-100 p-1 pt-0'}>
                        {witem.supply_external_witem && <div className={'w-full h-fit'}>
                            <div className={'text-gray-600'}>
                                <p>Дата
                                    обновления: <b className={getLeftColor()}>{(new Date(witem.supply_external_witem.updated_at)).toLocaleDateString()}</b>
                                </p>
                            </div>
                        </div>}
                    </div>
                    <div className={'w-full p-1 pt-0'}>
                        {witem.supply_product && <div className={'w-full h-fit'}>
                            <div className={'text-gray-600'}>
                                <p>Дата
                                    обновления: <b
                                        className={getRightsColor()}>{(new Date(witem.supply_product.updated_at)).toLocaleDateString()}</b>
                                </p>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className={'w-full text-center max-[700px]:hidden'}>
                    <button disabled={isTransferBlocked} onClick={() => {
                        createLocalSupplyProduct(null, null)
                    }}
                            className={'w-full border border-l-0 border-r-0 border-gray-100 px-3 py-1.5 font-bold hover:bg-gray-200 hover:border-gray-200 ' + (isTransferBlocked ? 'text-gray-500' : 'text-black')}>
                        Перенести
                    </button>
                </div>
            </td>
        </tr>
    )
}
