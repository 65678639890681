import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {IBrand, ICreateOrUpdateSupplyProduct, ISupply, IWare} from "../../models";
import {SupplySelector} from "../selectors/SupplySelector";
import {Error} from "../Error";
import {Loader} from "../Loader";
import {Pagination} from "../Pagination";
import {useCreateSupplyProductsMutation, useGetSupplyProductsQuery} from "../../store/supply-product-api";
import {SupplyExternalAndSupplyProductListItem} from "./SupplyExternalAndSupplyProductListItem";
import {WareSelector} from "../selectors/WareSelector";
import {BrandSelector} from "../selectors/BrandSelector";
import DatePicker from "react-date-picker";
import Select, {SingleValue, StylesConfig} from "react-select";

export function SupplyExternalAndSupplyProductList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [supplyID, setSupplyID] = useState<number>(Number(searchParams.get("supply-id")))
    const [page, setPage] = useState<number>(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
    const [pageSize, setPageSize] = useState<number>(Number(searchParams.get("page-size")) ? Number(searchParams.get("page-size")) : 50)
    const [isTransferBlocked, setIsTransferBlocked] = useState<boolean>(false);
    const supplyProductsToUpdate = new Map<number, ICreateOrUpdateSupplyProduct>();

    const [orderBy, setOrderBy] = useState<string>(searchParams.get("order-by") ? String(searchParams.get("order-by")) : 'id')
    const [isAsc, setIsAsc] = useState<boolean>(searchParams.get("is-asc") !== "false")
    let wareIds = searchParams.get("ware-ids");
    const [wareIDs, setWareIDs] = useState<number[]>(wareIds ? wareIds.split(',').map(Number) : [])
    let brandIds = searchParams.get("brand-ids");
    const [brandIDs, setBrandIDs] = useState<number[]>(brandIds ? brandIds.split(',').map(Number) : [])
    const [witemIDs, setWitemIDs] = useState<string>(searchParams.get("witem-ids") ? String(searchParams.get("witem-ids")) : '')

    const [dateFrom, setDateFrom] = useState<string>(searchParams.get('date-from') ? String(searchParams.get('date-from')) : '')
    const [dateTo, setDateTo] = useState<string>(searchParams.get('date-to') ? String(searchParams.get('date-to')) : '')
    const [isDateLastWeek, setDateLastWeek] = useState<boolean>(searchParams.get('is-date-last-week') === "true")

    const {data: response, isLoading, error } = useGetSupplyProductsQuery({page: page, pageSize: pageSize, supplyID: supplyID, orderBy: orderBy, isAsc: isAsc, witemIDs: prepareWItemIDs(), wareIDs: wareIDs, brandIDs: brandIDs, dateFrom: (dateFrom === '' ? null : dateFrom + ' 00:00:00'), dateTo: (dateTo === '' ? null : dateTo + ' 23:59:59') })
    const [createSupplyProducts, {isLoading: isCreating, error: createError}] = useCreateSupplyProductsMutation()

    useEffect(() => {
        if (response && response.supply_external_witems_supply_products[0] &&
            !response.supply_external_witems_supply_products[0].supply_external_witem) {
            setIsTransferBlocked(true)
        } else {
            setIsTransferBlocked(false)
        }
    }, [response]);

    useEffect(() => {
        setSupplyID(Number(searchParams.get("supply-id")))
        setPage(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
        setPageSize(Number(searchParams.get("page-size")) ? Number(searchParams.get("page-size")) : 50)
        setOrderBy(searchParams.get("order-by") ? String(searchParams.get("order-by")) : 'id')
        setIsAsc(searchParams.get("is-asc") !== "false")
        setWitemIDs(searchParams.get("witem-ids") ? String(searchParams.get("witem-ids")) : '')
        let wareIds = searchParams.get("ware-ids");
        setWareIDs(wareIds ? wareIds.split(',').map(Number) : [])
        let brandIds = searchParams.get("brand-ids");
        setBrandIDs(brandIds ? brandIds.split(',').map(Number) : [])
        setDateFrom(searchParams.get("date-from") ? String(searchParams.get('date-from')) : '')
        setDateTo(searchParams.get('date-to') ? String(searchParams.get('date-to')) : '')
        setDateLastWeek(searchParams.get('is-date-last-week') === "true")
    }, [searchParams]);

    function addSupplyProductsToUpdate(witemId: number, supplyProduct: ICreateOrUpdateSupplyProduct|null){
        if (supplyProduct) {
            supplyProductsToUpdate.set(witemId, supplyProduct)
        } else {
            supplyProductsToUpdate.delete(witemId)
        }
    }

    function prepareWItemIDs(): number[] {
        if (witemIDs !== '') {
            let stringWItemIDs = witemIDs.split(',');
            return stringWItemIDs.map((witem) => {
                return Number(witem);
            })
        }
        return [];
    }

    const saveChangesHandler = async () => {
        let supplyProductsToUpdateForRequest: Array<ICreateOrUpdateSupplyProduct> = [];
        supplyProductsToUpdate.forEach(function(value,key) {
            supplyProductsToUpdateForRequest.push(value)
        });
        try {
            await createSupplyProducts(supplyProductsToUpdateForRequest).unwrap()
        } catch {
        }
    }

    const selectSupplyHandler = (supply: ISupply|null): void => {
        selectPageHandler(1)
        selectPageSizeHandler(50)
        orderByHandler('id', false)
        searchParams.set('supply-id', String(supply ? supply.id : ''))
        setSearchParams(searchParams)
    }

    const selectPageHandler = (page: number) => {
        searchParams.set('page', String(page))
        setSearchParams(searchParams)
    }
    const selectPageSizeHandler = (pageSize: number) => {
        searchParams.set('page-size', String(pageSize))
        setSearchParams(searchParams)
    }

    const transferAllHandler = async () => {
        await transferSupplyProducts(true)
    }

    const transferUntouchedHandler = async () => {
        await transferSupplyProducts(false)
    }

    const transferSupplyProducts = async (isAll: boolean = false) => {
        response && response.supply_external_witems_supply_products.forEach(function(witem,key) {
            if (!isAll && witem.supply_product && (witem.supply_product.is_price_manual || witem.supply_product.is_stocks_manual)) {
                return;
            }
            let price = (witem.supply_external_witem && witem.supply_external_witem.prices.prices.length > 0) ? witem.supply_external_witem?.prices.prices[0].value : null
            let stocksStr = (witem.supply_external_witem && witem.supply_external_witem.in_stock.stocks.length > 0) ? witem.supply_external_witem?.in_stock.stocks[0].value : ''
            let stocks = Number((stocksStr).match(/\d+/))

            let supplyProductToUpdate = {
                supply_code: witem.supply_external_witem?.supply_code as string,
                supply_id: witem.supply_external_witem?.supply_id as number,
                price: {
                    value_in_centi_units: price ? price.value_in_centi_units : 0,
                    currency: (price ? price.currency : 'RUB') as string
                },
                stocks: stocks,
                is_price_manual: false,
                is_stocks_manual: false,
                witem_id: witem.witem_id
            } as ICreateOrUpdateSupplyProduct

            addSupplyProductsToUpdate(witem.witem_id, supplyProductToUpdate)
        });
        await saveChangesHandler()
    }

    const orderByHandler = (value: string, isSwitching: boolean = true) => {
        if (isSwitching && orderBy === value) {
            selectPageHandler(1)
            searchParams.set('is-asc', String(!isAsc))
            setSearchParams(searchParams)
            return
        }
        selectPageHandler(1)
        searchParams.set('order-by', value)
        searchParams.set('is-asc', String(true))
        setSearchParams(searchParams)
    }

    const getArrowFilters = (color: string = 'text-gray-800') => {
        if (isAsc) {
            return (
                <span className="ml-2"><svg className={'w-3 h-6 dark:text-white ' + color} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"></path>
                </svg></span>
            )
        } else {
            return (
                <span className="ml-2"><svg className={'w-3 h-6 dark:text-white ' + color} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"></path>
                </svg></span>
            )
        }
    }

    const selectWareHandler = (wares: IWare[]): void => {
        selectPageHandler(1)
        selectPageSizeHandler(50)
        searchParams.set('ware-ids', String(wares.map((ware) => { return ware.id }).join(',')))
        setSearchParams(searchParams)
    }

    const selectBrandHandler = (brand: IBrand[]|null): void => {
        selectPageHandler(1)
        selectPageSizeHandler(50)
        searchParams.set('brand-ids', String(brand ? brand.map((brand) => { return brand.id }).join(',') : null))
        setSearchParams(searchParams)
    }

    const witemHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let value = event.target.value.replace(/[^\d,]/g, '');
        selectPageHandler(1)
        selectPageSizeHandler(50)
        searchParams.set('witem-ids', String(value))
        setSearchParams(searchParams)
    }

    const dateHandler = (value: any, dateKey: string): void => {
        selectPageHandler(1)
        selectPageSizeHandler(50)
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        } as Intl.DateTimeFormatOptions;

        value = value !== null ? value.toLocaleDateString('en-US', options) : ''
        let lengthYear = value !== '' ? value.split('/')[2].length : 4

        if (lengthYear === 4) {
            searchParams.set(dateKey, String(value))
            setSearchParams(searchParams)
        }
    }

    const isDateLastWeekHandler = (): void => {
        selectPageHandler(1)
        selectPageSizeHandler(50)
        if (!isDateLastWeek) {
            const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            } as Intl.DateTimeFormatOptions;
            const date = new Date();
            searchParams.set('date-to', String(date.toLocaleDateString('en-US', options)));
            date.setDate(date.getDate() - 6)
            searchParams.set('date-from', String(date.toLocaleDateString('en-US', options)))
        } else {
            searchParams.set('date-to', '');
            searchParams.set('date-from', '')
        }
        searchParams.set('is-date-last-week', String(!isDateLastWeek))
        setSearchParams(searchParams)
    }

    const sortOptions: any[] = [
        {
            id: 0,
            isAsc: true,
            isVisible: true,
            orderBy: 'id',
            value: 'ID, по возрастанию',
        } as any,
        {
            id: 1,
            isAsc: false,
            isVisible: true,
            orderBy: 'id',
            value: 'ID, по убыванию',
        } as any,
        {
            id: 2,
            isAsc: true,
            isVisible: true,
            orderBy: 'name',
            value: 'Название, по возрастанию',
        } as any,
        {
            id: 3,
            isAsc: false,
            isVisible: true,
            orderBy: 'name',
            value: 'Название, по убыванию',
        } as any,
        {
            id: 4,
            isAsc: true,
            isVisible: !isTransferBlocked,
            orderBy: 'price_value',
            value: 'Цена, по возрастанию',
        } as any,
        {
            id: 5,
            isAsc: false,
            isVisible: !isTransferBlocked,
            orderBy: 'price_value',
            value: 'Цена, по убыванию',
        } as any,
        {
            id: 6,
            isAsc: true,
            isVisible: !isTransferBlocked,
            orderBy: 'price_currency',
            value: 'Валюта, по возрастанию',
        } as any,
        {
            id: 7,
            isAsc: false,
            isVisible: !isTransferBlocked,
            orderBy: 'price_currency',
            value: 'Валюта, по убыванию',
        } as any,
        {
            id: 8,
            isAsc: true,
            isVisible: !isTransferBlocked,
            orderBy: 'stocks',
            value: 'Оригинальный остаток, по возрастанию',
        } as any,
        {
            id: 9,
            isAsc: false,
            isVisible: !isTransferBlocked,
            orderBy: 'stocks',
            value: 'Оригинальный остаток, по убыванию',
        } as any,
        {
            id: 10,
            isAsc: true,
            isVisible: !isTransferBlocked,
            orderBy: 'updated_at',
            value: 'Дата обновления, по возрастанию',
        } as any,
        {
            id: 11,
            isAsc: false,
            isVisible: !isTransferBlocked,
            orderBy: 'updated_at',
            value: 'Дата обновления, по убыванию',
        } as any,
        {
            id: 12,
            isAsc: true,
            isVisible: isTransferBlocked,
            orderBy: 'supply_product_price_value',
            value: 'Цена, по возрастанию',
        } as any,
        {
            id: 13,
            isAsc: false,
            isVisible: isTransferBlocked,
            orderBy: 'supply_product_price_value',
            value: 'Цена, по убыванию',
        } as any,
        {
            id: 14,
            isAsc: true,
            isVisible: isTransferBlocked,
            orderBy: 'supply_product_price_currency',
            value: 'Валюта, по возрастанию',
        } as any,
        {
            id: 15,
            isAsc: false,
            isVisible: isTransferBlocked,
            orderBy: 'supply_product_price_currency',
            value: 'Валюта, по убыванию',
        } as any,
        {
            id: 16,
            isAsc: true,
            isVisible: isTransferBlocked,
            orderBy: 'supply_product_stocks',
            value: 'Остатки, по возрастанию',
        } as any,
        {
            id: 17,
            isAsc: false,
            isVisible: isTransferBlocked,
            orderBy: 'supply_product_stocks',
            value: 'Остатки, по убыванию',
        } as any,
        {
            id: 18,
            isAsc: true,
            isVisible: isTransferBlocked,
            orderBy: 'supply_product_updated_at',
            value: 'Дата обновления, по возрастанию',
        } as any,
        {
            id: 19,
            isAsc: false,
            isVisible: isTransferBlocked,
            orderBy: 'supply_product_updated_at',
            value: 'Дата обновления, по убыванию',
        } as any,

    ];

    const getSelectedSortOptions = () => {
        let id = (sortOptions.filter((value) => value.orderBy === orderBy))[0].id + (isAsc? 0 : 1);
        return sortOptions[id];
    }

    const sortOptionsHandler = (sortOptions: SingleValue<any>) => {
        selectPageHandler(1)
        searchParams.set('order-by', sortOptions.orderBy)
        searchParams.set('is-asc', String(sortOptions.isAsc))
        setSearchParams(searchParams)
    }

    const customStyles: StylesConfig = {
        option: (styles) => ({...styles, cursor: 'pointer'}),
        control: (styles) => ({...styles, cursor: 'text'}),
        indicatorsContainer: (styles) => ({...styles, cursor: 'pointer'})
    }

    return (
        <div className={'m-5 max-[700px]:m-0'}>
            <div className={'content-center'}>
                <h1 className={'text-2xl'}>Информация API поставщиков и наши данные </h1>
            </div>
            <div className={'min-[1851px]:w-[1600px]'}>
                <div className={'mt-10'}>
                    <SupplySelector onSelect={selectSupplyHandler} initSupplyID={supplyID} onSelectNull={() => selectSupplyHandler(null)} />
                </div>
                {supplyID && <>
                    <div className={'mt-2'}>
                        <WareSelector onSelect={() => {
                        }} initWareID={wareIDs} isDisabled={false} isClearable={true} onSelectNull={() => {}}
                        isMulti={true} onMultiSelect={selectWareHandler}/>
                    </div>
                    <div className={'mt-2'}>
                        <BrandSelector onSelect={() => {
                        }} initBrandID={brandIDs} isDisabled={false} isClearable={true} onSelectNull={() => {
                            selectBrandHandler(null)
                        }} isMulti={true} onMultiSelect={selectBrandHandler}/>
                    </div>
                    <div className={'mt-2'}>
                        <input
                            placeholder={'ID товара'}
                            onChange={witemHandler}
                            className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                            type={'text'} value={witemIDs ?? ''}/>
                    </div>
                    <div className={'relative'}>
                        <div id={'date-block'} className={'mt-6 flex'}>
                            <div className={'relative'}>
                                <DatePicker
                                    id={'date-picker-from'}
                                    className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                                    dayPlaceholder={'дд'}
                                    monthPlaceholder={'мм'}
                                    yearPlaceholder={'гггг'}
                                    format={'dd.MM.y'}
                                    maxDate={new Date(dateTo)}
                                    onChange={(value: any) => {
                                        dateHandler(value, 'date-from')
                                    }}
                                    value={dateFrom}
                                    disabled={isDateLastWeek}/>
                                <label htmlFor="date-picker-from"
                                       className="z-1 absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                    C
                                </label>
                            </div>
                            <div className={'mx-2.5 pt-2'}>
                                <svg className="w-6 h-6 text-gray-800" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M5 12h14"/>
                                </svg>

                            </div>
                            <div className={'relative'}>
                                <DatePicker
                                    id={'date-picker-to'}
                                    className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                                    dayPlaceholder={'дд'}
                                    monthPlaceholder={'мм'}
                                    yearPlaceholder={'гггг'}
                                    format={'dd.MM.y'}
                                    minDate={new Date(dateFrom)}
                                    onChange={(value: any) => {
                                        dateHandler(value, 'date-to')
                                    }}
                                    value={dateTo}
                                    disabled={isDateLastWeek}/>
                                <label htmlFor="date-picker-to"
                                       className="z-1 absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                    До
                                </label>
                            </div>
                        </div>
                        <label htmlFor="date-block"
                               className="z-1 absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 -top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-12 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                            Наша дата обновления
                        </label>
                    </div>
                    <div className={'relative mt-0 pl-0.5'}>
                        <input
                            id={'date-fixed'}
                            type={'checkbox'}
                            checked={isDateLastWeek}
                            onChange={isDateLastWeekHandler}
                            className={' border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}/>
                        <label htmlFor="date-fixed"
                               className="z-1 select-none absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-3/4 left-4 px-2 origin-[0] bg-white">
                            Данные за последнюю неделю
                        </label>
                    </div>
                    <div className={'relative mt-5 min-[1591px]:hidden'}>
                        <Select
                            id={'sort-product-price'}
                            className={'appearance-none rounded w-full py-2 leading-tight focus:outline-none focus:shadow-outline'}
                            placeholder={'Сортировка'}
                            options={sortOptions.filter((value) => value.isVisible === true)}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.id}
                            onChange={sortOptionsHandler}
                            styles={customStyles}
                            value={getSelectedSortOptions()}/>
                        <label htmlFor="sort-product-price"
                               className="z-1 absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                            Сортировка
                        </label>
                    </div>
                    <div>
                        <div>
                            <h4 className={'text-xl'}>Список товаров для выбранного поставщика</h4>
                        </div>
                        {error && <Error error={JSON.stringify(error)}/>}
                        {isLoading && <Loader/>}
                        {createError && <Error error={JSON.stringify(createError)}/>}
                        {isCreating && <Loader/>}
                        <Pagination
                            initPage={page}
                            initPageSize={pageSize}
                            allCount={response ? response.all_count : 0}
                            onSelectPageHandler={selectPageHandler}
                            onSelectPageSizeHandler={selectPageSizeHandler}/>
                    </div>
                    <table className={'table-auto mt-5 w-[1600px] border-collapse max-[1850px]:w-full'}>
                        <thead className={'bg-blue-200 max-[1590px]:hidden'}>
                        <tr>
                            <th colSpan={2}>
                            </th>
                            <th colSpan={5}>
                                API поставщика
                            </th>
                            <th>
                            </th>
                            <th colSpan={4}>
                                Наши данные по поставщику
                            </th>
                        </tr>
                        <tr>
                            <th className={'w-[100px] max-[1850px]:min-w-[90px]'}>
                                <button className="flex ml-3" onClick={() => {
                                    orderByHandler('id')
                                }}>
                                    <span>ID товара</span>
                                    {orderBy === 'id' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[200px] text-center max-[1850px]:min-w-[150px]'}>
                                <button className="flex ml-3" onClick={() => {
                                    orderByHandler('name')
                                }}>
                                    <span>Название</span>
                                    {orderBy === 'name' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[150px] text-center max-[1850px]:min-w-[100px]'}>
                                <button disabled={isTransferBlocked} className="flex ml-3" onClick={() => {
                                    orderByHandler('price_value')
                                }}>
                                    <span>Цена</span>
                                    {orderBy === 'price_value' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[100px] text-center max-[1850px]:min-w-[80px]'}>
                                <button disabled={isTransferBlocked} className="flex ml-3" onClick={() => {
                                    orderByHandler('price_currency')
                                }}>
                                    <span>Валюта</span>
                                    {orderBy === 'price_currency' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[150px] text-center max-[1850px]:min-w-[100px]'}>
                                <button disabled={isTransferBlocked} className="flex ml-3" onClick={() => {
                                    orderByHandler('stocks')
                                }}>
                                    <span>Оригинальный остаток</span>
                                    {orderBy === 'stocks' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[150px] text-center max-[1850px]:min-w-[80px]'}>
                                <span>Числовой остаток</span>
                            </th>
                            <th className={'min-w-[200px] max-w-[200px] max-[1850px]:min-w-[100px]'}>
                                <button disabled={isTransferBlocked} className="flex align-baseline w-full justify-center" onClick={() => {
                                    orderByHandler('updated_at')
                                }}>
                                    <span>Дата обновления</span>
                                    {orderBy === 'updated_at' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[200px] text-center max-[1850px]:min-w-[100px]'}>
                                Перенос товаров
                            </th>
                            <th className={'w-[150px] text-center max-[1850px]:min-w-[100px]'}>
                                <button disabled={!isTransferBlocked} className="flex ml-3" onClick={() => {
                                    orderByHandler('supply_product_price_value')
                                }}>
                                    <span>Цена</span>
                                    {orderBy === 'supply_product_price_value' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[100px] text-center max-[1850px]:min-w-[80px]'}>
                                <button disabled={!isTransferBlocked} className="flex ml-3" onClick={() => {
                                    orderByHandler('supply_product_price_currency')
                                }}>
                                    <span>Валюта</span>
                                    {orderBy === 'supply_product_price_currency' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[150px] text-center max-[1850px]:min-w-[80px]'}>
                                <button disabled={!isTransferBlocked} className="flex ml-3" onClick={() => {
                                    orderByHandler('supply_product_stocks')
                                }}>
                                    <span>Остатки</span>
                                    {orderBy === 'supply_product_stocks' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[200px] text-center max-[1850px]:min-w-[110px]'}>
                                <button disabled={!isTransferBlocked} className="flex align-baseline" onClick={() => {
                                    orderByHandler('supply_product_updated_at')
                                }}>
                                    <span>Дата обновления</span>
                                    {orderBy === 'supply_product_updated_at' ? getArrowFilters() : ''}
                                </button>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {response && response.supply_external_witems_supply_products.map(witem =>
                            <SupplyExternalAndSupplyProductListItem key={witem.supply_code} witem={witem}
                                                                    isTransferBlocked={isTransferBlocked}
                                                                    addSupplyProduct={addSupplyProductsToUpdate}/>
                        )}
                        <tr className={'w-[1600px] max-[1590px]:hidden'}>
                            <td colSpan={6}></td>
                            <td colSpan={1}>
                                <button disabled={isTransferBlocked} onClick={transferUntouchedHandler}
                                        className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight ' + (isTransferBlocked ? 'hidden' : '')}>Перенести
                                    нетронутые
                                </button>
                            </td>
                            <td colSpan={4}></td>
                        </tr>
                        <tr className={'w-[1600px] max-[1590px]:hidden'}>
                            <td colSpan={6}></td>
                            <td colSpan={1}>
                                <button disabled={isTransferBlocked} onClick={transferAllHandler}
                                        className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight ' + (isTransferBlocked ? 'hidden' : '')}>Перезаписать
                                    все
                                </button>
                            </td>
                            <td colSpan={1}></td>
                            <td colSpan={1}>
                                <button onClick={saveChangesHandler}
                                        className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight'}>Принять
                                </button>
                            </td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td className={'w-full flex-col min-[1591px]:hidden'}>
                                <div className={'flex justify-center mt-2 max-[580px]:hidden'}>
                                    <div className={'w-full'}></div>
                                    <div className={'w-full flex-col'}>
                                        <button disabled={false} onClick={transferUntouchedHandler}
                                                className={'bg-blue-200 appearance-none border rounded break-words w-full py-2 px-3 leading-tight '  + (isTransferBlocked ? 'hidden' : '')}>Перенести
                                            нетронутые
                                        </button>
                                    </div>
                                    <div className={'w-full'}></div>
                                </div>
                                <div className={'flex justify-center max-[580px]:flex-col'}>
                                    <div className={'w-full max-[580px]:hidden'}></div>
                                    <div className={'w-full flex-col min-[581px]:hidden'}>
                                        <button disabled={false} onClick={transferUntouchedHandler}
                                                className={'bg-blue-200 appearance-none border rounded break-words w-full py-2 px-3 leading-tight ' + (isTransferBlocked ? 'hidden' : '')}>Перенести
                                            нетронутые
                                        </button>
                                    </div>
                                    <div className={'w-full'}>
                                        <button disabled={false} onClick={transferAllHandler}
                                                className={'bg-blue-200 appearance-none border rounded break-words w-full py-2 px-3 leading-tight ' + (isTransferBlocked ? 'hidden' : '')}>Перезаписать
                                            все
                                        </button>
                                    </div>
                                    <div className={'w-full flex-col'}>
                                        <button onClick={saveChangesHandler}
                                                className={'bg-blue-200 appearance-none border rounded break-words w-full py-2 px-3 leading-tight'}>Принять
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </>}
            </div>
        </div>
    )
}
