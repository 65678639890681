import React from "react";
import {ProductPriceChangesList} from "../components/product-price-changes/ProductPriceChangesList";

export function ProductPriceChangesPage() {
    return (
        <div className={'m-5'}>
            <ProductPriceChangesList />
        </div>
    )
}
