import {ILogEntry, IProductPriceRuleLogEntryDataValue} from "../../models";
import React, {useState} from "react";
import {cutTags} from "../selectors/functions";

export interface ProductPriceRuleLogEntriesListItemProps {
    productPriceRuleLogEntry: ILogEntry
}

export function ProductPriceRuleLogEntriesListItem({productPriceRuleLogEntry}: ProductPriceRuleLogEntriesListItemProps) {
    const [data, setData] = useState<IProductPriceRuleLogEntryDataValue|null>(productPriceRuleLogEntry.data ? JSON.parse(productPriceRuleLogEntry.data) : null)

    const translationAction = () => {
        switch (productPriceRuleLogEntry.action) {
            case 'create':
                return 'Создание'
            case 'update':
                return 'Обновление'
            case 'remove':
                return 'Удаление'
        }
    }

    return (
        <tr>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 border-l-0 max-[950px]:hidden'}>
                {productPriceRuleLogEntry.entity_id}
            </td>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 max-[950px]:hidden'}>
                {translationAction()}
            </td>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 max-[950px]:hidden'}>
                {productPriceRuleLogEntry.user_login ? productPriceRuleLogEntry.user_login : '-'}
            </td>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 max-[950px]:hidden'}>
                {`${(new Date(productPriceRuleLogEntry.created_at)).toLocaleDateString()} ${(new Date(productPriceRuleLogEntry.created_at)).toLocaleTimeString()}`}
            </td>
            <td className={'p-3 pl-5 border border-gray-50 border-b-gray-200 border-r-0 max-[950px]:hidden'}>
                {(data && data.ware_ids.length !== 0) && 'ID каталогов: ' + data.ware_ids.join(', ')} {(data && data.ware_ids.length !== 0) &&
                <br/>}
                {(data && data.city_id) && 'ID города: ' + data.city_id} {(data && data.city_id) && <br/>}
                {(data && data.witem_ids.length !== 0) && 'ID товаров: ' + data.witem_ids.join(', ')} {(data && data.witem_ids.length !== 0) &&
                <br/>}
                {(data && data.brand_ids.length !== 0) && 'ID брендов: ' + data.brand_ids.join(', ')} {(data && data.brand_ids.length !== 0) &&
                <br/>}
                {(data && data.centi_percent_delta) && 'Процент наценки: ' + (data.centi_percent_delta / 100).toString().replace('.', ',') + '%'} {(data && data.centi_percent_delta) &&
                <br/>}
                {(data && data.absolute_delta) && 'Абсолютная наценка: ' + (data.absolute_delta.value_in_centi_units / 100).toString().replace('.', ',') + ' ' + data.absolute_delta.currency} {(data && data.absolute_delta) &&
                <br/>}
                {(data && data.price) && 'Цена: ' + (data.price.value_in_centi_units / 100).toString().replace('.', ',') + ' ' + data.price.currency}
            </td>
            <td className={'p-3 w-full border break-words border-b-gray-300 border-r-0 border-l-0 min-[951px]:hidden'}>
                <div className={'flex w-full justify-center max-[700px]:flex-wrap'}>
                    <div className={'mr-5 flex-col justify-start w-full'}>
                        <div>
                            <b>ID правила наценки:</b> {productPriceRuleLogEntry.entity_id}
                        </div>
                        <div>
                            <b>Действие:</b> {translationAction()}
                        </div>
                        {productPriceRuleLogEntry.user_login && <div>
                            <b>Пользователь:</b> {productPriceRuleLogEntry.user_login}
                        </div>}
                    </div>
                    <div className={'flex-col justify-start w-full'}>
                        <b>Лог:</b><br/>
                        {(data && data.ware_ids.length !== 0) && 'ID каталогов: ' + data.ware_ids.join(', ')} {(data && data.ware_ids.length !== 0) &&
                        <br/>}
                        {(data && data.city_id) && 'ID города: ' + data.city_id} {(data && data.city_id) && <br/>}
                        {(data && data.witem_ids.length !== 0) && 'ID товаров: ' + data.witem_ids.join(', ')} {(data && data.witem_ids.length !== 0) &&
                        <br/>}
                        {(data && data.brand_ids.length !== 0) && 'ID брендов: ' + data.brand_ids.join(', ')} {(data && data.brand_ids.length !== 0) &&
                        <br/>}
                        {(data && data.centi_percent_delta) && 'Процент наценки: ' + (data.centi_percent_delta / 100).toString().replace('.', ',') + '%'} {(data && data.centi_percent_delta) &&
                        <br/>}
                        {(data && data.absolute_delta) && 'Абсолютная наценка: ' + (data.absolute_delta.value_in_centi_units / 100).toString().replace('.', ',') + ' ' + data.absolute_delta.currency} {(data && data.absolute_delta) &&
                        <br/>}
                        {(data && data.price) && 'Цена: ' + (data.price.value_in_centi_units / 100).toString().replace('.', ',') + ' ' + data.price.currency}
                    </div>
                </div>
                <div className={'mt-5  text-gray-600'}>
                    <p>Дата
                        обновления: <b>{`${(new Date(productPriceRuleLogEntry.created_at)).toLocaleDateString()} ${(new Date(productPriceRuleLogEntry.created_at)).toLocaleTimeString()}`}</b>
                    </p>
                </div>
            </td>
        </tr>
    )
}
