import {CitySelector} from "../components/selectors/CitySelector";
import {ICity} from "../models";
import {SalesAgentProductStateList} from "../components/sales-agent/SalesAgentProductStateList";
import {useState} from "react";
import {useSearchParams} from "react-router-dom";

export function SalesAgentProductStatePage() {

    const [searchParams, setSearchParams] = useSearchParams()
    const initPage = Number(searchParams.get("page"))
    const initPageSize = Number(searchParams.get("page-size"))
    const initCityID = Number(searchParams.get("city-id"))
    const initWitemSearch = searchParams.get("witem-search")
    const [cityID, setCityID] = useState<number>(initCityID)

    const selectCityHandler = (city: ICity): void => {
        setCityID(city.city_id)
        searchParams.set('city-id', String(city.city_id))
        setSearchParams(searchParams)
    }

    const selectSalesAgentProductPageHandler = (page: number) => {
        searchParams.set('page', String(page))
        setSearchParams(searchParams)
    }
    const selectSalesAgentProductPageSizeHandler = (pageSize: number) => {
        searchParams.set('page-size', String(pageSize))
        setSearchParams(searchParams)
    }

    const witemSearchChangeHandler = (witemSearch: string) => {
        searchParams.set('witem-search', witemSearch)
        setSearchParams(searchParams)
    }

    return (
        <div className={'m-5 max-w-[1034px]'}>
            <div className={'content-center'}>
                <h1 className={'text-2xl'}>Товары торговых агентов</h1>
            </div>
            <div>
                <div className={'mt-10'}>
                    <CitySelector onSelect={selectCityHandler} initCityID={initCityID} isDisabled={false}/>
                </div>
                <div className={'mt-10'}>
                    {cityID && <SalesAgentProductStateList
                        cityID={cityID}
                        initPage={initPage ? initPage : 1}
                        initPageSize={initPageSize ? initPageSize : 500}
                        initWitemSearch={initWitemSearch ? initWitemSearch : ""}
                        onSelectPageHandler={selectSalesAgentProductPageHandler}
                        onSelectPageSizeHandler={selectSalesAgentProductPageSizeHandler}
                        onChangeWitemSearch={witemSearchChangeHandler}
                    />}
                </div>
            </div>
        </div>
    )
}
