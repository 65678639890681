import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {ISalesAgentProductState} from "../models";
import {prepareHeadersHandler, responseHandler} from "./functions";

interface SalesAgentProductStateListResponse {
    sales_agent_product_states: ISalesAgentProductState[]
    all_count: number
}

export const salesAgentProductStateApi = createApi({
    reducerPath: 'salesAgentProductStateApi',
    tagTypes: ['SalesAgentProductStates'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/sales-agent-product-state',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getSalesAgentProductStates: builder.query<SalesAgentProductStateListResponse, { city_id: number, page: number, page_size: number, witem_search: string|null }>({
            query: (arg) => ({
                url: '',
                method: 'POST',
                body: {
                    "city_id": arg.city_id,
                    "page": arg.page,
                    "page_size": arg.page_size,
                    "witem_search": arg.witem_search
                },
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.sales_agent_product_states.map(({id}) => ({type: 'SalesAgentProductStates' as const, id})),
                    {type: 'SalesAgentProductStates', id: 'LIST'},
                ]
                : [{type: 'SalesAgentProductStates', id: 'LIST'}],
        })
    })
})

export const {
    useGetSalesAgentProductStatesQuery
} = salesAgentProductStateApi
