import React from "react";
import {ProductPriceRuleList} from "../components/product-price-rule/ProductPriceRuleList";

export function ProductPriceRulePage() {

    return (
        <div className={'mt-5 ml-5 mr-5'}>
            <ProductPriceRuleList/>
        </div>
    )
}
