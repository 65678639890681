import React from "react";
import {components} from "react-select";

export const CustomOption = ({ children, isSelected, ...props }: any) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    return (
        <components.Option
            {...newProps}
            className={"custom-option"}
            id={newProps.innerProps.id}
            tabIndex={newProps.innerProps.tabIndex}
            onClick={newProps.innerProps.onClick}
            isSelected={isSelected}
            isFocused={false}
        >
            {children}
        </components.Option>
    );
};
