import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {prepareHeadersHandler, responseHandler} from "./functions";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {ISupplyProductChange} from "../models";

interface SupplyProductChangesListResponse {
    supply_product_changes: ISupplyProductChange[],
    all_count: number
}

export const supplyProductChangesApi = createApi({
    reducerPath: 'supplyProductChangesApi',
    tagTypes: ['SupplyProductChanges'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/supply-product-change',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getSupplyProductChanges: builder.query<SupplyProductChangesListResponse, { page: number, perPage: number, supplyID: number|null, witemID: number|null, wareID: number|null, login: string|null}>({
            query: (arg) => ({
                url: 'list',
                method: 'POST',
                body: {
                    "page": arg.page,
                    "per_page": arg.perPage,
                    "supply_id": arg.supplyID,
                    "witem_id": arg.witemID,
                    "ware_id": arg.wareID,
                    "user_login": arg.login,
                },
            }),
            transformErrorResponse(baseQueryReturnValue: BaseQueryError<any>): string {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.supply_product_changes.map(({id}) => ({type: 'SupplyProductChanges' as const, id})),
                    {type: 'SupplyProductChanges', id: 'LIST'},
                ]
                : [{type: 'SupplyProductChanges', id: 'LIST'}],
        }),
    })
})

export const {
    useGetSupplyProductChangesQuery
} = supplyProductChangesApi
