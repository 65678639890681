import {IWare} from "../models";
import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {prepareHeadersHandler, responseHandler} from "./functions";

interface WareListResponse {
    wares: IWare[]
}

export const waresApi = createApi({
    reducerPath: 'waresApi',
    tagTypes: ['Wares'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/wares',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getWares: builder.query<IWare[], number>({
            query: (wareID: number) => ({
                url: 'get',
                method: 'POST',
                body: {
                    ware_id: wareID
                },
            }),
            transformResponse: (baseQueryReturnValue: WareListResponse): IWare[] => baseQueryReturnValue.wares,
            transformErrorResponse(baseQueryReturnValue: BaseQueryError<any>): string {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.map(({id}) => ({type: 'Wares' as const, id})),
                    {type: 'Wares', id: 'LIST'},
                ]
                : [{type: 'Wares', id: 'LIST'}],
        })
    })
})

export const {useGetWaresQuery} = waresApi
