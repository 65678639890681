import React, {useState} from "react";
import {useAuthenticationMutation} from "../store/authentication-api";
import {Loader} from "../components/Loader";
import {Error} from "../components/Error";
export function Login() {
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [authentication, {isLoading, error}] = useAuthenticationMutation();
    const sendForm = async (event: React.FormEvent) => {
        event.preventDefault()
        try {
            const response = await authentication({login, password}).unwrap();
            const token = response.token;
            if (token) {
                localStorage.setItem('user', JSON.stringify(response));
                window.location.href = window.location.pathname;
            }
        } catch (error) {
            console.error('error', error);
        }
    }

    return (
        <>
            <div className={'fixed bg-black/50 top-0 right-0 left-0 bottom-0 z-10'}/>
            <div id="default-modal"
                 className="overflow-y-auto overflow-x-hidden fixed flex top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative p-5 bg-white rounded-lg shadow">
                        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 break-words">Авторизация</h2>
                            </div>

                            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                <form className="space-y-6" action="#" method="POST" onSubmit={sendForm}>
                                    <div>
                                        <label htmlFor="login"
                                               className="block text-sm font-medium leading-6 text-gray-900 break-words">Логин</label>
                                        <div className="mt-2">
                                            <input id="login" name="login" type="text" required value={login}
                                                   onChange={(e) => {
                                                       setLogin(e.target.value)
                                                   }}
                                                   className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label htmlFor="password"
                                                   className="block text-sm font-medium leading-6 text-gray-900 break-words">Пароль</label>
                                        </div>
                                        <div className="mt-2">
                                            <input id="password" name="password" type="password"
                                                   autoComplete="current-password"
                                                   required value={password} onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                                   className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                    </div>

                                    <div>
                                        <button type="submit"
                                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                                        focus-visible:outline-indigo-600 break-words">Войти
                                        </button>
                                    </div>
                                    <div>
                                        {isLoading && <Loader/>}
                                        {error && <Error error={JSON.stringify(error)}/>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
