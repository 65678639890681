import React from "react";
import {
    SupplyExternalAndSupplyProductList
} from "../components/supply-product/SupplyExternalAndSupplyProductList";

export function SupplyExternalAndSupplyProductPage() {

    return (
        <div className={'m-5'}>
            <SupplyExternalAndSupplyProductList/>
        </div>
    )
}
