import {Route, Routes} from "react-router-dom";
import {Navigation} from "./components/navigation/Navigation";
import {SalesAgentProductStatePage} from "./pages/SalesAgentProductStatePage";
import {ProductPriceRulePage} from "./pages/ProductPriceRulePage";
import {ProductPricePage} from "./pages/ProductPricePage";
import {SupplyExternalAndSupplyProductPage} from "./pages/SupplyExternalAndSupplyProductPage";
import {NotFoundPage} from "./pages/NotFoundPage";
import {HomePage} from "./pages/HomePage";
import {ProductPriceChangesPage} from "./pages/ProductPriceChangesPage";
import {SupplyProductChangesPage} from "./pages/SupplyProductChangesPage";
import {ProductPriceRuleLogEntriesPage} from "./pages/ProductPriceRuleLogEntriesPage";

function App() {
    return (
        <div>
            <div className={'w-48 fixed z-50 max-[1380px]:w-40 max-[900px]:w-full'}>
                <Navigation/>
            </div>
            <div className={'flex max-[900px]:flex-col'}>
                <div className={'w-48 h-screen flex-shrink-0  max-[1380px]:w-40 flex max-[900px]:w-full max-[900px]:h-[72px]'}> </div>
                <div id={'main'} className={'w-[calc(100vw-12rem)] h-screen overflow-auto max-[1380px]:w-[calc(100vw-10rem)] max-[900px]:w-full max-[900px]:h-[calc(100vh-72px)]'}>
                    <Routes>
                        <Route path={'/'} element={<HomePage/>} />
                        <Route path={'/sales-agent'} element={<SalesAgentProductStatePage/>} />
                        <Route path={'/product-price-rule'} element={<ProductPriceRulePage/>} />
                        <Route path={'/product-price'} element={<ProductPricePage/>} />
                        <Route path={'/supply-external-and-supply-product-list'} element={<SupplyExternalAndSupplyProductPage/>} />
                        <Route path={'/product-price-changes-list'} element={<ProductPriceChangesPage/>} />
                        <Route path={'/supply-product-changes-list'} element={<SupplyProductChangesPage/>} />
                        <Route path={'/product-price-rule-log-entries-list'} element={<ProductPriceRuleLogEntriesPage/>} />
                        <Route path={'/*'} element={<NotFoundPage/>} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default App;
