import React from "react";
import {SupplyProductChangesList} from "../components/supply-product-changes/SupplyProductChangesList";

export function SupplyProductChangesPage() {
    return (
        <div className={'m-5'}>
            <SupplyProductChangesList />
        </div>
    )
}
