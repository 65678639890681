import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {prepareHeadersHandler, responseHandler} from "./functions";
import {IFile} from "../models";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const filesApi = createApi({
    reducerPath: 'filesApi',
    tagTypes: ['Files'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/files',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getWItemsWithoutRuleByCity: builder.mutation<IFile, { cityID: number }>({
            query: (arg): any => ({
                url: 'get-witems-without-rule-by-city',
                method: 'POST',
                body: {
                    "city_id": arg.cityID
                }
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            invalidatesTags: (result) => [{type: 'Files', id: 'LIST'}]
        }),
    })
})

export function downloadDocument(response: IFile) {
    const linkSource = `${response.content}`;
    const downloadLink = document.createElement('a');
    const fileName = `${response.file_name}.${response.extension}`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
}

export const {useGetWItemsWithoutRuleByCityMutation} = filesApi;
