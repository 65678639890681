import {ICity, ICreateOrUpdateProductPriceRule} from "../../models";
import React, {useEffect, useState} from "react";
import {CitySelector} from "../selectors/CitySelector";
import {WareSelector} from "../selectors/WareSelector";
import {BrandSelector} from "../selectors/BrandSelector";
import {useCreateProductPriceRuleMutation} from "../../store";
import {Error} from "../Error";
import {Loader} from "../Loader";

interface CreateProductPriceRuleProps {
    closeModal: () => void
}

export function CreateProductPriceRule({closeModal}: CreateProductPriceRuleProps) {
    const [createRule, {isLoading: isCreating, error: createError}] = useCreateProductPriceRuleMutation()

    const [wareIDs, setWareIDs] = useState<number[]>([])
    const [city, setCity] = useState<ICity|null>(null)
    const [brandIDs, setBrandIDs] = useState<number[]>( [])

    const [witemIDs, setWitemIDs] = useState<string>('')
    const [centiPercentDelta, setCentiPercentDelta] = useState<string|null>(null)
    const [absoluteDelta, setAbsoluteDelta] = useState<string|null>(null)
    const [price, setPrice] = useState<string|null>(null)
    const [error, setError] = useState('');

    function prepareWItemIDs(): number[] {
        if (witemIDs !== '') {
            let stringWItemIDs = witemIDs.split(',');
            return stringWItemIDs.map((witem) => {
                return Number(witem);
            })
        }
        return [];
    }

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault()
        setError('');
        if (Math.sign(Number(absoluteDelta)) === -1) {
            setError('Значение поля "Абсолютная наценка" должно быть положительным');
            return;
        }
        if (Math.sign(Number(price)) === -1) {
            setError('Значение поля "Цена" должно быть положительным');
            return;
        }
        if (city === null) {
            setError('Город обязателен к указанию')
            return
        }
        let absoluteDeltaNumber = absoluteDelta ? Number(absoluteDelta.replace(',', '.')) : null;
        let priceNumber = price ? Number(price.replace(',', '.')) : null;
        let rule: ICreateOrUpdateProductPriceRule = {
            id: '',
            ware_ids: wareIDs,
            city_id: city.city_id,
            brand_ids: brandIDs,
            witem_ids: prepareWItemIDs(),
            centi_percent_delta: centiPercentDelta ? Number(centiPercentDelta.replace(',', '.')) : null,
            absolute_delta: absoluteDeltaNumber,
            price: priceNumber
        }

        rule = JSON.parse(JSON.stringify(rule))

        try {
            await createRule(rule).unwrap()
            closeModal()
        } catch {}
    }

    const witemHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.replace(/[^\d,]/g, ' ');
        let index = value.indexOf(' ')
        await setWitemIDs(value.replace(' ', ''))
        if (index !== -1) {
            event.target.setSelectionRange(index,index);
        }
    }

    const centiPercentDeltaHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        (event.target.value.trim().length === 0) ? setCentiPercentDelta(null) : setCentiPercentDelta(event.target.value);
    }

    const setNumberOrNull = (str: string): string|null => {
        if (str.trim().length === 0) {
            return null;
        }
        return str;
    }

    const [isAlert, setAlert] = useState(false);
    useEffect(() => {
        setAlert(!!(createError || error));
    }, [createError, error]);

    return (
        <form onSubmit={submitHandler} className="px-4 pt-3 pb-1 mb-1 relative">
            <div className={'absolute top-0 bottom-0'}>
                <span
                    className={"bg-white flex items-center text-black p-3 z-10 break-words rounded drop-shadow-lg sticky top-5 w-full transition-opacity " + (isAlert ? 'visible' : 'hidden')}>
                    <div className={'w-full break-words'}>
                        {error && <Error error={error}/>}
                        {createError && <Error error={JSON.stringify(createError)}/>}
                    </div>
                    <div className={'px-3'}>
                        <button
                            className={'text-center align-sub'}
                            type={'button'}
                            onClick={() => {
                                setAlert(false)
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                                 stroke="black" strokeWidth="3"
                                 className="stroke-gray-300 hover:stroke-gray-400"><line
                                x1="4" x2="15" y1="4" y2="15"></line><line x1="15" x2="4" y1="4" y2="15"></line></svg>
                        </button>
                    </div>
                </span>
            </div>
            {isCreating && <Loader/>}
            <div className="mb-4 relative">
                <CitySelector onSelect={(city) => setCity(city)} initCityID={city ? city.city_id : null}
                              isDisabled={false}/>
            </div>
            <div className="mb-4 relative">
                <WareSelector
                    onSelect={() => {
                    }}
                    initWareID={wareIDs}
                    isDisabled={false}
                    isClearable={true}
                    onSelectNull={() => {
                        setWareIDs([])
                    }}
                    isMulti={true}
                    onMultiSelect={(wares) => {
                        setWareIDs(wares.map((ware) => {
                            return ware.id
                        }))
                    }}/>
            </div>
            <div className="mb-4 relative">
                <BrandSelector
                    onSelect={() => {
                    }}
                    initBrandID={brandIDs}
                    isDisabled={false}
                    isClearable={true}
                    onSelectNull={() => {
                        setBrandIDs([])
                    }}
                    isMulti={true}
                    onMultiSelect={(brands) => {
                        setBrandIDs(brands.map((brand) => {
                            return brand.id
                        }))
                    }}/>
            </div>
            <div className="mb-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="witemID">
                    Товар
                </label>
                <input
                    value={witemIDs ?? ''}
                    onChange={witemHandler}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="witemID" type="text" placeholder="Товар"/>
            </div>
            <div className="mb-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="centi_percent_delta">
                    Процент наценки
                </label>
                <input
                    value={centiPercentDelta ?? ''}
                    onKeyDown={(e) => {
                        if (['e', '+', '-', '.', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onChange={centiPercentDeltaHandler}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    id="centi_percent_delta" type="number" placeholder="Процент наценки"/>
            </div>
            <div className="mb-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="absolute_delta">
                    Абсолютная наценка
                </label>
                <input
                    value={absoluteDelta ?? ''}
                    onKeyDown={(e) => {
                        if (['e', '+', '-', '.', ',', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onChange={(e) => (setAbsoluteDelta(setNumberOrNull(e.target.value)))}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    id="absolute_delta" type="number" placeholder="Абсолютная наценка"/>
            </div>
            <div className="mb-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
                    Цена
                </label>
                <input
                    value={price ?? ''}
                    onKeyDown={(e) => {
                        if (['e', '+', '-', '.', ',', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onChange={(e) => (setPrice(setNumberOrNull(e.target.value)))}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    id="price" type="number" placeholder="Цена"/>
            </div>
            <div className="mb-2 relative">
                <button
                    className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit">
                    Создать
                </button>
            </div>
        </form>
    )
}
