import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {ICreateOrUpdateProductPriceRule, IProductPriceRule} from "../models";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {prepareHeadersHandler, responseHandler} from "./functions";
import {productPriceApi} from "./product-price-api";
import {productPriceRuleLogEntriesApi} from "./product-price-rule-log-entries-api"

interface ProductPriceRuleListResponse {
    product_price_rule: IProductPriceRule[],
    all_count: number
}

export const productPriceRuleApi = createApi({
    reducerPath: 'productPriceRuleApi',
    tagTypes: ['ProductPriceRules'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/product-price-rules',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getProductPriceRules: builder.query<ProductPriceRuleListResponse, { page: number, perPage: number, isAsc: boolean, dateFrom: string|null, dateTo: string|null, login: string|null, wareIDs: number[], cityID: number|null, brandIDs: number[],  wItemIDs: number[] }>({
            query: (arg) => ({
                url: 'list',
                method: 'POST',
                body: {
                    "page": arg.page,
                    "per_page": arg.perPage,
                    "login": arg.login,
                    "is_asc": arg.isAsc,
                    "date-from": arg.dateFrom,
                    "date-to": arg.dateTo,
                    "ware_ids": arg.wareIDs,
                    "city_id": arg.cityID,
                    "brand_ids": arg.brandIDs,
                    "witem_ids": arg.wItemIDs
                },
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.product_price_rule.map(({id}) => ({type: 'ProductPriceRules' as const, id})),
                    {type: 'ProductPriceRules', id: 'LIST'},
                ]
                : [{type: 'ProductPriceRules', id: 'LIST'}],
        }),
        getProductPriceRule: builder.query<IProductPriceRule, string>({
            query: (id) => ({
                url: 'get',
                method: 'POST',
                body: {
                    id
                },
            }),
            providesTags: (result, error, id) =>
                [{type: 'ProductPriceRules', id: id}],
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
        }),
        deleteProductPriceRule: builder.mutation<any, string>({
            query: (id: string): any => ({
                url: 'delete',
                method: 'POST',
                body: {
                    id
                }
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            invalidatesTags: [{type: 'ProductPriceRules', id: 'LIST'}],
            async onQueryStarted(_, { dispatch }) {
                dispatch(productPriceApi.util.invalidateTags(["ProductPrices"]));
                dispatch(productPriceRuleLogEntriesApi.util.invalidateTags(["ProductPriceRuleLogEntries"]));
            },
        }),
        updateProductPriceRule: builder.mutation<IProductPriceRule, any>({
            query: (rule: ICreateOrUpdateProductPriceRule): any => ({
                url: 'update',
                method: 'POST',
                body: rule
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            invalidatesTags: (result, error, rule) => [{type: 'ProductPriceRules', id: rule.id}],
            async onQueryStarted(_, { dispatch }) {
                dispatch(productPriceApi.util.invalidateTags(["ProductPrices"]));
                dispatch(productPriceRuleLogEntriesApi.util.invalidateTags(["ProductPriceRuleLogEntries"]));
            },
        }),
        createProductPriceRule: builder.mutation<IProductPriceRule, any>({
            query: (rule: ICreateOrUpdateProductPriceRule): any => ({
                url: 'create',
                method: 'POST',
                body: rule
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            invalidatesTags: [{type: 'ProductPriceRules', id: 'LIST'}],
            async onQueryStarted(_, { dispatch }) {
                dispatch(productPriceApi.util.invalidateTags(["ProductPrices"]));
                dispatch(productPriceRuleLogEntriesApi.util.invalidateTags(["ProductPriceRuleLogEntries"]));
            }
        }),
    })
})

export const {
    useGetProductPriceRulesQuery,
    useDeleteProductPriceRuleMutation,
    useUpdateProductPriceRuleMutation,
    useCreateProductPriceRuleMutation
} = productPriceRuleApi;

