import {
    createGetHeight,
    flattenGroupedChildren,
    getCurrentIndex
} from './util';

import * as React from 'react';
import { VariableSizeList as List } from 'react-window';
import {OptionProps, GroupBase, MenuListProps} from 'react-select';

interface OptionTypeBase {
    [key: string]: any;
}

function MenuList ({...props}: MenuListProps<any>) {
    const children = React.useMemo(
        () => {
            const children = React.Children.toArray(props.children);

            const head = children[0] || {};

            if (React.isValidElement<OptionProps<OptionTypeBase, boolean, GroupBase<OptionTypeBase>>>(head)) {
                const {
                    props: {
                        data: {
                            options = []
                        } = {},
                    } = {},
                } = head;
                const groupedChildrenLength = options.length;
                const isGrouped = groupedChildrenLength > 0;
                const flattenedChildren = isGrouped && flattenGroupedChildren(children);

                return isGrouped
                    ? flattenedChildren
                    : children;
            }
            else {
                return [];
            }
        },
        [props.children]
    );

    const { getStyles } = props;
    const loadingMsgStyles = getStyles('loadingMessage', props);
    const noOptionsMsgStyles = getStyles('noOptionsMessage', props);
    const optionStyles = getStyles('option', {...props, label: '', type: 'option', data: '', isDisabled: false, isFocused: false, isSelected: false} as OptionProps);
    const getHeight = createGetHeight({
        noOptionsMsgStyles,
        optionStyles,
        loadingMsgStyles,
    });

    const heights = React.useMemo(() => children.map(getHeight), [children]);
    const currentIndex = React.useMemo(() => getCurrentIndex(children), [children]);

    const itemCount = children.length;

    const [measuredHeights, setMeasuredHeights] = React.useState<{}[]>([]);

    const { maxHeight, paddingBottom = 0, paddingTop = 0, ...menuListStyle } = getStyles('menuList', props);
    const totalHeight = React.useMemo(() => {
        return heights.reduce((sum: any, height: any, idx: any) => {
            if (measuredHeights[idx]) {
                return sum + measuredHeights[idx];
            }
            else {
                return sum + height;
            }
        }, 0);
    }, [heights, measuredHeights]);
    const totalMenuHeight = totalHeight + paddingBottom + paddingTop;
    const menuHeight = Math.min(300, totalMenuHeight);
    const estimatedItemSize = Math.floor(totalHeight / itemCount);

    const {
        innerRef,
        selectProps,
    } = props;

    const { classNamePrefix, isMulti } = selectProps || {};
    const list = React.useRef<List>(null);

    React.useEffect(
        () => {
            setMeasuredHeights([]);
        },
        [props.children]
    );

    const setMeasuredHeight = ({ index, measuredHeight }: any) => {
        if (measuredHeights[index] !== undefined && measuredHeights[index] === measuredHeight) {
            return;
        }
        setMeasuredHeights(measuredHeights => ({
            ...measuredHeights,
            [index]: measuredHeight,
        }));
        if (list.current) {
            list.current.resetAfterIndex(index);
        }
    };

    React.useEffect(() => {
        if (currentIndex >= 0 && list.current !== null && !isMulti) {
            list.current.scrollToItem(currentIndex, 'start');
        }
    },[currentIndex, children, list]);

    return (
        <List
            className={classNamePrefix ? `${classNamePrefix}__menu-list${isMulti ? ` ${classNamePrefix}__menu-list--is-multi`: ''}` : ''}
            ref={list}
            outerRef={innerRef}
            estimatedItemSize={estimatedItemSize}
            innerElementType={React.forwardRef(({ style, ...rest }, ref) => (
                <div
                    ref={ref}
                    style={{
                        ...style,
                        height: `${ parseFloat(style.height) + (paddingBottom as number) + (paddingTop as number) }px`
                    }}
                    {...rest}
                />
            ))}
            height={menuHeight}
            width="100%"
            itemCount={itemCount}
            itemData={children}
            itemSize={index => measuredHeights[index] || heights[index]}
        >
            {({ data, index, style}) => {
                return (
                    <div
                        style={{
                            ...style,
                            top: `${parseFloat((style as {top: any}).top.toString()) + (paddingTop as number)}px`,
                        }}>
                        <MenuItem
                            data={data[index]}
                            index={index}
                            setMeasuredHeight={setMeasuredHeight}
                        />
                    </div>
                )
            }}
        </List>
    );
}

function MenuItem({data, index, setMeasuredHeight}: any) {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useLayoutEffect(() => {
        if (ref.current) {
            const measuredHeight = ref.current.getBoundingClientRect().height;
            setMeasuredHeight({ index, measuredHeight });
        }
    }, [ref.current]);

    return (
        <div key={`option-${index}`} ref={ref}>{data}</div>
    );
}
export default MenuList;
