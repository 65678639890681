import {IBrand} from "../models";
import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {prepareHeadersHandler, responseHandler} from "./functions";

interface BrandListResponse {
    brands: IBrand[]
}

export const brandsApi = createApi({
    reducerPath: 'brandsApi',
    tagTypes: ['Brands'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/brands',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getBrands: builder.query<IBrand[], {}>({
            query: () => ({
                url: 'list',
                method: 'POST',
                body: {},
            }),
            transformResponse: (baseQueryReturnValue: BrandListResponse): IBrand[] => baseQueryReturnValue.brands,
            transformErrorResponse(baseQueryReturnValue: BaseQueryError<any>): string {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.map(({id}) => ({type: 'Brands' as const, id})),
                    {type: 'Brands', id: 'LIST'},
                ]
                : [{type: 'Brands', id: 'LIST'}],
        }),
    })
})

export const {useGetBrandsQuery} = brandsApi;
