import {IBrand} from "../../models";
import {useGetBrandsQuery} from "../../store";
import Select, {createFilter, MultiValue, SingleValue, StylesConfig} from "react-select";
import {Error} from "../Error";
import {CustomOption} from "./CustomSelectOption";
import {useState} from "react";
import MenuList from "./MenuList";

interface BrandSelectorProps {
    onSelect: (brand: IBrand) => void
    initBrandID: number[] | number | null
    isDisabled: boolean
    isClearable: boolean
    onSelectNull: () => void
    isCustomStyles?: boolean
    isMulti?: boolean
    onMultiSelect?: (ware: IBrand[]) => void
}

export function BrandSelector(
    {onSelect, initBrandID, isDisabled = false, isClearable = false, onSelectNull = () => {}, isCustomStyles = true, isMulti = false, onMultiSelect = () => {}}: BrandSelectorProps
) {
    const {data: brands = [], isLoading, error} = useGetBrandsQuery({});

    const initBrandIndex = isMulti ? brands.map((brand, index) => {
        if ((initBrandID as number[]).includes(brand.id)) {
            return index
        }
    }) : brands.findIndex((brand: IBrand) => brand.id === initBrandID)

    const onChangeHandler = (newValue: SingleValue<IBrand> | MultiValue<IBrand>) => {
        if (newValue === null) {
            if (isClearable) {
                onSelectNull()
            }
            return
        }
        isMulti ? onMultiSelect(newValue as IBrand[]) : onSelect(newValue as IBrand);
    }

    const customStyles: StylesConfig<IBrand> = {
        option: (styles) => ( isCustomStyles ? {...styles, cursor: 'pointer'} : {...styles}),
        control: (styles) => ( isCustomStyles ? {...styles, cursor: 'text'} : {...styles}),
        indicatorsContainer: (styles) => ( isCustomStyles ? {...styles, cursor: 'pointer'} : {...styles})
    }
    const [inputValue, setInputValue] = useState('')

    return (
        <div>
            {error && <Error error={JSON.stringify(error)}/>}
            {brands && <Select
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                hideSelectedOptions={false}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isLoading={isLoading}
                placeholder={'Выбор бренда'}
                options={brands}
                getOptionLabel={(brand: IBrand) => `${brand.name} (ID=${brand.id})`}
                getOptionValue={(brand: IBrand) => String(brand.id)}
                onChange={onChangeHandler}
                value={isMulti ? (initBrandIndex as number[]).map(brandIndex => brands[brandIndex]) : brands[initBrandIndex as number]}
                styles={customStyles}
                components={{ Option: CustomOption, MenuList: MenuList }}
                classNamePrefix="custom-select"
                filterOption={createFilter({ignoreAccents: false})}
                onInputChange={(value, meta) => {
                    if (meta.action !== "set-value") setInputValue(value)
                }}
                inputValue={inputValue}
            />}
        </div>
    );
}
