import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import type {ICity} from "../models";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {prepareHeadersHandler, responseHandler} from "./functions";

interface CityListResponse {
    result: ICity[]
}

export const citiesApi = createApi({
    reducerPath: 'citiesApi',
    tagTypes: ['Cities'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/city',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getCities: builder.query<ICity[], boolean>({
            query: (isActive: boolean) => ({
                url: 'list',
                method: 'POST',
                body: {
                    filter: {
                        is_active: isActive
                    }
                },
            }),
            transformResponse: (baseQueryReturnValue: CityListResponse): ICity[] => baseQueryReturnValue.result,
            transformErrorResponse(baseQueryReturnValue: BaseQueryError<any>): string {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.map(({city_id}) => ({type: 'Cities' as const, city_id})),
                    {type: 'Cities', id: 'LIST'},
                ]
                : [{type: 'Cities', id: 'LIST'}],
        }),
    })
})

export const {useGetCitiesQuery} = citiesApi;
