import {IProductPriceChange} from "../../models";
import React from "react";

export interface ProductPriceChangesListItemProps {
    productPriceChange: IProductPriceChange
}

export function ProductPriceChangesListItem({productPriceChange}: ProductPriceChangesListItemProps) {

    return (
        <tr>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 border-l-0 max-[950px]:hidden'}>
                {`${productPriceChange.city.name} (${productPriceChange.city.short_name} ID=${productPriceChange.city.city_id})`}
            </td>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 max-[950px]:hidden'}>
                {productPriceChange.product_id}
            </td>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 max-[950px]:hidden'}>
                {productPriceChange.user ? productPriceChange.user.login : '-'}
            </td>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 max-[950px]:hidden'}>
                {`${(new Date(productPriceChange.created_at)).toLocaleDateString()} ${(new Date(productPriceChange.created_at)).toLocaleTimeString()}`}
            </td>
            <td className={'p-3 pl-5 border border-gray-50 border-b-gray-200 border-r-0 max-[950px]:hidden'}>
                {
                    'Цена: ' + (productPriceChange.state.value.price_in_centi_units.value_in_centi_units/100) + ' ' + productPriceChange.state.value.price_in_centi_units.currency
                }<br/>{
                    'Тип сохранения: ' + (productPriceChange.state.value.is_price_manual ? 'вручную' : 'расчетная')
                }
            </td>
            <td className={'p-3 w-full border break-words border-b-gray-300 border-r-0 border-l-0 min-[951px]:hidden'}>
                <div className={'flex w-full justify-center max-[700px]:flex-wrap'}>
                    <div className={'mr-5 flex-col justify-start w-full'}>
                        <div>
                            <b>Город:</b> {`${productPriceChange.city.name} (${productPriceChange.city.short_name} ID=${productPriceChange.city.city_id})`}
                        </div>
                        <div>
                            <b>Товар:</b> {productPriceChange.product_id}
                        </div>
                        {productPriceChange.user && <div>
                            <b>Пользователь:</b> {productPriceChange.user.login}
                        </div>}
                    </div>
                    <div className={'flex-col justify-start w-full'}>
                        <b>Предыдущее состояние:</b><br/>
                        {
                            'Цена: ' + (productPriceChange.state.value.price_in_centi_units.value_in_centi_units / 100) + ' ' + productPriceChange.state.value.price_in_centi_units.currency
                        }<br/>{
                            'Тип сохранения: ' + (productPriceChange.state.value.is_price_manual ? 'вручную' : 'расчетная')
                        }
                    </div>
                </div>
                <div className={'mt-5  text-gray-600'}>
                    <p>Дата обновления: <b>{`${(new Date(productPriceChange.created_at)).toLocaleDateString()} ${(new Date(productPriceChange.created_at)).toLocaleTimeString()}`}</b></p>
                </div>
            </td>
        </tr>
    )
}
