import {Error} from "../Error";
import {ICity} from "../../models";
import Select, {createFilter, MultiValue, SingleValue, StylesConfig} from "react-select";
import {useGetCitiesQuery} from "../../store";
import {CustomOption} from "./CustomSelectOption";

interface CitySelectorProps {
    onSelect: (city: ICity) => void
    initCityID: number | null
    isDisabled: boolean
    isClearable?: boolean
    onSelectNull?: () => void
    isCustomStyles?: boolean
}

export function CitySelector(
    {onSelect, initCityID, isDisabled = false, isClearable = false, onSelectNull = () => {}, isCustomStyles = true}: CitySelectorProps
) {
    const {data: cities = [], isLoading, error} = useGetCitiesQuery(true);

    const initCityIndex = cities.findIndex((city: ICity) => city.city_id === initCityID)

    const onChangeHandler = (newValue: SingleValue<ICity> | MultiValue<ICity>) => {
        if (newValue === null) {
            if (isClearable) {
                onSelectNull()
            }
            return
        }
        onSelect(newValue as ICity)
    }

    const customStyles: StylesConfig<ICity> = {
        option: (styles) => ( isCustomStyles ? {...styles, cursor: 'pointer'} : {...styles}),
        control: (styles) => ( isCustomStyles ? {...styles, cursor: 'text'} : {...styles}),
        indicatorsContainer: (styles) => ( isCustomStyles ? {...styles, cursor: 'pointer'} : {...styles})
    }

    return (
        <div>
            {error && <Error error={JSON.stringify(error)}/>}
            {cities && <Select
                isMulti={false}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isLoading={isLoading}
                placeholder={'Выбор города'}
                options={cities}
                getOptionLabel={(city: ICity) => `${city.name} (${city.short_name} ID=${city.city_id})`}
                getOptionValue={(city: ICity) => String(city.city_id)}
                onChange={onChangeHandler}
                value={cities[initCityIndex]}
                styles={customStyles}
                components={{ Option: CustomOption }}
                classNamePrefix="custom-select"
                filterOption={createFilter({ignoreAccents: false})}
            />}
        </div>
    );
}
