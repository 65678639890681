import axios from "axios";
import {IError} from "../../models";
import {AxiosError} from "axios";

export interface SendProductProps {
    witemIDs: number[]
    cityID: number,
    onSendHandler: (error: string) => void
}

interface sendProductRequest {
    witem_ids: number[]
    city_id: number
}

interface sendProductResponse {
}

export function sendProduct(
    {witemIDs, cityID, onSendHandler}: SendProductProps
) {

    const request: sendProductRequest = {
        witem_ids: witemIDs,
        city_id: cityID
    }
    axios.post<sendProductResponse>('/api/v1/sales-agent-product/send', request)
        .then(
            () => {
                onSendHandler('')
            })
        .catch((e) => {
            const error = e as AxiosError<IError>
            if (undefined !== error.response) {
                onSendHandler(error.response.data.error.message)
                return
            }
            onSendHandler("Неизвестная ошибка")
        })
}
