import React, {useEffect, useState} from "react";

interface PaginationProps{
    initPage: number
    initPageSize: number
    allCount: number
    onSelectPageHandler: (page: number) => void
    onSelectPageSizeHandler: (pageSize: number) => void
}

export function Pagination(
    {initPage, initPageSize, allCount, onSelectPageHandler, onSelectPageSizeHandler}: PaginationProps
) {
    const [page, setPage] = useState<number>(Number(initPage))
    const [pageSize, setPageSize] = useState<number>(Number(initPageSize))

    useEffect(() => {
        setPageHandler(initPage)
        setPageSizeHandler(initPageSize)
    }, [initPage, initPageSize]);

    const pageSizeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const size = Number(event.target.value)
        if (size > 0) {
            setPageSizeHandler(size)
        } else {
            setPageSizeHandler(10)
        }
        setPageHandler(1)
    }

    const setPageSizeHandler = (pageSize: number) => {
        setPageSize(pageSize)
        onSelectPageSizeHandler(pageSize)
    }
    const setPageHandler = (page: number) => {
        setPage(page)
        onSelectPageHandler(page)
    }

    return (
        <>
            <nav className={'mt-5 max-[450px]:flex max-[450px]:justify-center'}>
                <ul className="inline-flex -space-x-px max-[450px]:flex-col max-[450px]:items-center">
                    {(page - 1 >= 0 || Math.ceil(allCount / pageSize) >= page + 1) &&
                        <>
                            <li className={'min-[451px]:hidden mb-1 flex'}>
                                {[2, 1].map(pageID => (
                                    page - pageID > 0 && <button
                                        className={'px-3 py-2 my-0.5 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ' + (page - pageID - 1 > 0 && page - pageID - 1 === page - 2 ? 'rounded-l-none' : '')}
                                        onClick={() => setPageHandler(page - pageID)}
                                    >
                                        {page - pageID}
                                    </button>
                                ))}
                                <button
                                    className={'px-3 py-2 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white ' + (page === 1 ? 'rounded-l-lg' : '') + (Math.ceil(allCount / pageSize) === page ? ' rounded-r-lg' : '')}
                                >
                                    {page}
                                </button>
                                {[1, 2].map(pageID => (
                                    Math.ceil(allCount / pageSize) >= page + pageID && <button
                                        className={'px-3 py-2 my-0.5 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ' + (Math.ceil(allCount / pageSize) >= page + pageID + 1 && pageID !== 2 ? 'rounded-r-none' : '')}
                                        onClick={() => setPageHandler(page + pageID)}
                                    >
                                        {page + pageID}
                                    </button>
                                ))}
                            </li>
                            <li className={'min-[451px]:hidden'}>
                                {page - 1 > 0 && <button
                                    className={'px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ' + ((Math.ceil(allCount / pageSize) >= page + 1) ? '' : 'rounded-r-lg')}
                                    onClick={() => setPageHandler(page - 1)}
                                >
                                    Previous
                                </button>}
                                {Math.ceil(allCount / pageSize) >= page + 1 && <button
                                    className={'px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ' + ((page - 1 > 0) ? '' : 'rounded-l-lg')}
                                    onClick={() => setPageHandler(page + 1)}
                                >
                                    Next
                                </button>}
                            </li>
                        </>
                    }
                    <li className={'max-[450px]:hidden'}>
                        {page - 1 > 0 &&
                            <button
                                className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={() => setPageHandler(page - 1)}
                            >
                                Previous
                            </button>
                        }
                    </li>
                    <li className={'max-[450px]:hidden'}>
                        {page - 2 > 0 &&
                            <button
                                className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={() => setPageHandler(page - 2)}
                            >
                                {page - 2}
                            </button>
                        }
                    </li>
                    <li className={'max-[450px]:hidden'}>
                        {page - 1 > 0 &&
                            <button
                                className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={() => setPageHandler(page - 1)}
                            >
                                {page - 1}
                            </button>
                        }
                    </li>
                    <li className={'max-[450px]:hidden'}>
                        <button
                            className="px-3 py-2 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                        >
                            {page}
                        </button>
                    </li>
                    <li className={'max-[450px]:hidden'}>
                        {Math.ceil(allCount / pageSize) >= page + 1 &&
                            <button
                                className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={() => setPageHandler(page + 1)}
                            >
                                {page + 1}
                            </button>
                        }
                    </li>
                    <li className={'max-[450px]:hidden'}>
                        {Math.ceil(allCount / pageSize) >= page + 2 &&
                            <button
                                className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={() => setPageHandler(page + 2)}
                            >
                                {page + 2}
                            </button>
                        }
                    </li>
                    <li className={'max-[450px]:hidden'}>
                        {Math.ceil(allCount / pageSize) >= page + 1 &&
                            <button
                                className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={() => setPageHandler(page + 1)}
                            >
                                Next
                            </button>
                        }
                    </li>

                </ul>
            </nav>
            <div>Всего: {allCount}</div>
            <label>Элементов на странице:</label>
            <input value={pageSize} className={'border'} type={'text'} min={'10'} max={'10000'}
                   onChange={pageSizeChangeHandler}/>
        </>
    )
}
