import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {IBrand, ICity, IMoney, ISetProductPrice, IWare} from "../../models";
import {useCreateProductPricesMutation, useGetProductPricesQuery} from "../../store/product-price-api";
import {CitySelector} from "../selectors/CitySelector";
import {Error} from "../Error";
import {Loader} from "../Loader";
import {Pagination} from "../Pagination";
import {ProductPriceListItem} from "./ProductPriceListItem";
import {WareSelector} from "../selectors/WareSelector";
import {BrandSelector} from "../selectors/BrandSelector";
import {downloadDocument, useGetWItemsWithoutRuleByCityMutation} from "../../store/files-api";
import Select, {SingleValue, StylesConfig} from "react-select";

export function ProductPriceList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [cityID, setCityID] = useState<number>(Number(searchParams.get("city-id")))
    const [page, setPage] = useState<number>(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
    const [pageSize, setPageSize] = useState<number>(Number(searchParams.get("page-size")) ? Number(searchParams.get("page-size")) : 50)
    const productPricesToUpdate = new Map<number, ISetProductPrice>();

    const [orderBy, setOrderBy] = useState<string>(searchParams.get("order-by") ? String(searchParams.get("order-by")) : 'id')
    const [isAsc, setIsAsc] = useState<boolean>(searchParams.get("is-asc") !== "false")
    const [wareID, setWareID] = useState<number|null>(Number(searchParams.get("ware-id")) ? Number(searchParams.get("ware-id")) : null)
    const [brandID, setBrandID] = useState<number|null>(Number(searchParams.get("brand-id")) ? Number(searchParams.get("brand-id")) : null)
    const [witemID, setWitemID] = useState<string>(searchParams.get("witem-id") ? String(searchParams.get("witem-id")) : '')

    const {data: response, isLoading, error } = useGetProductPricesQuery({page: page, perPage: pageSize, cityID: cityID, orderBy: orderBy, isAsc: isAsc, brandID: brandID, wareID: wareID, witemID: (witemID === '' ? null : Number(witemID))})
    const [createProductPrices, {isLoading: isCreating, error: createError}] = useCreateProductPricesMutation()

    useEffect(() => {
        setCityID(Number(searchParams.get("city-id")))
        setPage(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
        setPageSize(Number(searchParams.get("page-size")) ? Number(searchParams.get("page-size")) : 50)
        setOrderBy(searchParams.get("order-by") ? String(searchParams.get("order-by")) : 'id')
        setIsAsc(searchParams.get("is-asc") !== "false")
        setWitemID(searchParams.get("witem-id") ? String(searchParams.get("witem-id")) : '')
        setWareID(Number(searchParams.get("ware-id")) ? Number(searchParams.get("ware-id")) : null)
        setBrandID(Number(searchParams.get("brand-id")) ? Number(searchParams.get("brand-id")) : null)
    }, [searchParams]);

    function addProductPriceToUpdate(witemId: number, productPrice: ISetProductPrice|null){
        if (productPrice) {
            productPricesToUpdate.set(witemId, productPrice)
        } else {
            productPricesToUpdate.delete(witemId)
        }
    }

    const saveChangesHandler = async () => {
        let productPricesToUpdateForRequest: Array<ISetProductPrice> = [];
        productPricesToUpdate.forEach(function(value,key) {
            productPricesToUpdateForRequest.push(value)
        });
        try {
            await createProductPrices(productPricesToUpdateForRequest).unwrap()
        } catch {
        }
    }

    const selectCityHandler = (city: ICity): void => {
        selectPageHandler(1)
        selectPageSizeHandler(50)
        searchParams.set('city-id', String(city.city_id))
        setSearchParams(searchParams)
    }

    const selectPageHandler = (page: number) => {
        searchParams.set('page', String(page))
        setSearchParams(searchParams)
    }
    const selectPageSizeHandler = (pageSize: number) => {
        searchParams.set('page-size', String(pageSize))
        setSearchParams(searchParams)
    }

    const transferAllHandler = async () => {
        await transferProducts(true)
    }

    const transferUntouchedHandler = async () => {
        await transferProducts(false)
    }

    const transferProducts = async (isAll: boolean = false) => {
        response && response.calculated_and_current_product_prices.forEach(function(witem,key) {
            if (!isAll && witem.product_price && witem.product_price.is_price_manual) {
                return;
            }

            let productToUpdate = {
                witem_id: witem.witem_id,
                city_id: cityID,
                price: {
                    value_in_centi_units: witem.calculated_product_price.value_in_centi_units,
                    currency: witem.calculated_product_price.currency
                } as IMoney,
                is_price_manual: false
            } as ISetProductPrice

            addProductPriceToUpdate(witem.witem_id, productToUpdate)
        });
        await saveChangesHandler()
    }

    const orderByHandler = (value: string, isSwitching: boolean = true) => {
        if (isSwitching && orderBy === value) {
            selectPageHandler(1)
            searchParams.set('is-asc', String(!isAsc))
            setSearchParams(searchParams)
            return
        }
        selectPageHandler(1)
        searchParams.set('order-by', value)
        searchParams.set('is-asc', String(true))
        setSearchParams(searchParams)
    }

    const getArrowFilters = (color: string = 'text-gray-800') => {
        if (isAsc) {
            return (
                <span className="ml-2"><svg className={'w-3 h-6 dark:text-white ' + color} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"></path>
                </svg></span>
            )
        } else {
            return (
                <span className="ml-2"><svg className={'w-3 h-6 dark:text-white ' + color} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"></path>
                </svg></span>
            )
        }
    }

    const selectWareHandler = (ware: IWare|null): void => {
        selectPageHandler(1)
        selectPageSizeHandler(50)
        searchParams.set('ware-id', String(ware ? ware.id : null))
        setSearchParams(searchParams)
    }

    const selectBrandHandler = (brand: IBrand|null): void => {
        selectPageHandler(1)
        selectPageSizeHandler(50)
        searchParams.set('brand-id', String(brand ? brand.id : null))
        setSearchParams(searchParams)
    }

    const witemHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectPageHandler(1)
        selectPageSizeHandler(50)
        searchParams.set('witem-id', String(event.target.value))
        setSearchParams(searchParams)
    }

    const [getWItemsWithoutRuleByCity, {isLoading: fileLoading, error: fileError}] = useGetWItemsWithoutRuleByCityMutation()
    const saveHandler = async () => {
        const file = await getWItemsWithoutRuleByCity({cityID}).unwrap();
        downloadDocument(file);
    }
    const [dropdown, setDropdown] = useState(false);

    const sortOptions = [
        {
            id: 0,
            isAsc: true,
            value: 'ID, по возрастанию',
        } as any,
        {
            id: 1,
            isAsc: false,
            value: 'ID, по убыванию',
        } as any,
        {
            id: 2,
            isAsc: true,
            value: 'Название, по возрастанию',
        } as any,
        {
            id: 3,
            isAsc: false,
            value: 'Название, по убыванию',
        } as any,
    ];

    const getSelectedSortOptions = () => {
        let optionId = (orderBy === 'name') ? (isAsc ? 2 : 3) : (isAsc ? 0 : 1);
        return sortOptions[optionId];
    }

    const sortOptionsHandler = (sortOptions: SingleValue<any>) => {
        let orderBy = (sortOptions.id === 3 || sortOptions.id === 2) ? 'name' : 'id';
        selectPageHandler(1)
        searchParams.set('order-by', orderBy)
        searchParams.set('is-asc', String(sortOptions.isAsc))
        setSearchParams(searchParams)
    }

    const customStyles: StylesConfig = {
        option: (styles) => ({...styles, cursor: 'pointer'}),
        control: (styles) => ({...styles, cursor: 'text'}),
        indicatorsContainer: (styles) => ({...styles, cursor: 'pointer'})
    }

    return (
        <div className={'m-5 max-[700px]:m-0'}>
            <div className={'content-center'}>
                <h1 className={'text-2xl'}>Наценки товаров</h1>
            </div>
            <div className={'min-[1851px]:w-[1600px]'}>
                <div className={'mt-10'}>
                    <CitySelector onSelect={selectCityHandler} initCityID={cityID ? cityID : null} isDisabled={false} />
                </div>
                {cityID && <>
                    <div className={'mt-2'}>
                        <WareSelector onSelect={selectWareHandler} initWareID={wareID} isDisabled={false}
                                      isClearable={true} onSelectNull={() => {
                            selectWareHandler(null)
                        }}/>
                    </div>
                    <div className={'mt-2'}>
                        <BrandSelector onSelect={selectBrandHandler} initBrandID={brandID} isDisabled={false}
                                       isClearable={true} onSelectNull={() => {
                            selectBrandHandler(null)
                        }}/>
                    </div>
                    <div className={'mt-2'}>
                        <input
                            placeholder={'ID товара'}
                            onChange={witemHandler}
                            className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                            type={'number'} value={witemID ?? ''}/>
                    </div>
                    <div className={'relative mt-5 min-[1618px]:hidden'}>
                        <Select
                            id={'sort-product-price'}
                            className={'appearance-none rounded w-full py-2 leading-tight focus:outline-none focus:shadow-outline'}
                            placeholder={'Сортировка'}
                            options={sortOptions}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.id}
                            onChange={sortOptionsHandler}
                            styles={customStyles}
                            value={getSelectedSortOptions()}/>
                        <label htmlFor="sort-product-price"
                               className="z-1 absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                            Сортировка
                        </label>
                    </div>
                    <div className={'mt-2'}>
                        <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown"
                                className="rounded border-none hover:text-gray-700 leading-tight focus:outline-none px-2 py-2 text-center inline-flex items-center"
                                type="button" onClick={() => setDropdown(!dropdown)}>Отчёты
                            {dropdown && <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                            </svg>}
                            {!dropdown && <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 4 4-4 4 4"/>
                            </svg>}
                        </button>
                        <div id="dropdown"
                             className={"rounded border " + (dropdown ? '' : 'hidden')}>
                            <ul className="text-sm text-gray-700"
                                aria-labelledby="dropdownDefaultButton">
                                <li>
                                    {fileError && <Error error={JSON.stringify(fileError)}/>}
                                    <button onClick={saveHandler}
                                            disabled={fileLoading}
                                            className={'flex items-center text-start appearance-none w-full py-2 px-5 leading-tight transition-all hover:bg-gray-100 duration-100 disabled:bg-gray-100 disabled:text-gray-500'}>
                                        <div>Товары без правил наценок</div>
                                        {fileLoading &&
                                            <div role="status" className={'ml-5'}>
                                                <svg aria-hidden="true"
                                                     className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                                     viewBox="0 0 100 101" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="currentColor"/>
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"/>
                                                </svg>
                                                <span className="sr-only">Loading...</span>
                                            </div>}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h4 className={'text-xl'}>Список товаров для выбранного города</h4>
                        </div>
                        {error && <Error error={JSON.stringify(error)}/>}
                        {isLoading && <Loader/>}
                        {createError && <Error error={JSON.stringify(createError)}/>}
                        {isCreating && <Loader/>}
                        <Pagination
                            initPage={page}
                            initPageSize={pageSize}
                            allCount={response ? response.all_count : 0}
                            onSelectPageHandler={selectPageHandler}
                            onSelectPageSizeHandler={selectPageSizeHandler}/>
                    </div>
                    <table className={'table-auto mt-5 w-[1600px] border-collapse max-[1850px]:w-full'}>
                        <thead className={'bg-blue-200 max-[1617px]:hidden'}>
                        <tr>
                            <th className={'w-[100px] max-[1850px]:min-w-[90px]'}>
                                <button className="flex ml-3" onClick={() => {
                                    orderByHandler('id')
                                }}>
                                    <span>ID товара</span>
                                    {orderBy === 'id' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[200px] max-[1850px]:min-w-[150px]'}>
                                <button className="flex ml-3" onClick={() => {
                                    orderByHandler('name')
                                }}>
                                    <span>Название</span>
                                    {orderBy === 'name' ? getArrowFilters() : ''}
                                </button>
                            </th>
                            <th className={'w-[200px] max-[1850px]:min-w-[150px]'}>
                                Расчётная цена
                            </th>
                            <th className={'w-[100px] max-[1850px]:min-w-[80px]'}>
                                Валюта
                            </th>
                            <th className={'w-[100px] max-[1850px]:min-w-[120px]'}>
                                Маржа
                            </th>
                            <th className={'w-[75px] max-[1850px]:min-w-[75px]'}>
                                Правило наценки
                            </th>
                            <th className={'w-[120px] max-[1850px]:min-w-[120px]'}>
                                Перенос товаров
                            </th>
                            <th className={'w-[200px] max-[1850px]:min-w-[160px]'}>
                                Текущая цена
                            </th>
                            <th className={'w-[100px] max-[1850px]:min-w-[80px]'}>
                                Валюта
                            </th>
                            <th className={'w-[200px] max-[1850px]:min-w-[120px]'}>
                                Дата обновления
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {response && response.calculated_and_current_product_prices.map(productPrice =>
                            <ProductPriceListItem key={productPrice.witem_id} witem={productPrice} cityID={cityID}
                                                  addProductPrice={addProductPriceToUpdate}/>
                        )}
                        <tr className={'w-[1600px] max-[1850px]:w-full max-[1617px]:hidden'}>
                            <td colSpan={6}></td>
                            <td colSpan={1}>
                                <button disabled={false} onClick={transferUntouchedHandler}
                                        className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight'}>Перенести
                                    нетронутые
                                </button>
                            </td>
                            <td colSpan={1}></td>
                        </tr>
                        <tr className={'w-[1600px] max-[1850px]:w-full max-[1617px]:hidden'}>
                            <td colSpan={4}></td>
                            <td colSpan={1}></td>
                            <td colSpan={1}></td>
                            <td colSpan={1}>
                                <button disabled={false} onClick={transferAllHandler}
                                        className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight'}>Перезаписать
                                    все
                                </button>
                            </td>
                            <td colSpan={1}>
                                <button onClick={saveChangesHandler}
                                        className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight'}>Принять
                                </button>
                            </td>
                            <td colSpan={1}></td>
                        </tr>
                        <tr>
                            <td className={'w-full flex-col min-[1618px]:hidden'}>
                                <div className={'flex justify-center mt-2 max-[580px]:hidden'}>
                                    <div className={'w-full'}></div>
                                    <div className={'w-full flex-col'}>
                                        <button disabled={false} onClick={transferUntouchedHandler}
                                                className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight'}>Перенести
                                            нетронутые
                                        </button>
                                    </div>
                                    <div className={'w-full'}></div>
                                </div>
                                <div className={'flex justify-center max-[580px]:flex-col'}>
                                    <div className={'w-full max-[580px]:hidden'}></div>
                                    <div className={'w-full flex-col min-[581px]:hidden'}>
                                        <button disabled={false} onClick={transferUntouchedHandler}
                                                className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight'}>Перенести
                                            нетронутые
                                        </button>
                                    </div>
                                    <div className={'w-full'}>
                                        <button disabled={false} onClick={transferAllHandler}
                                                className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight'}>Перезаписать
                                            все
                                        </button>
                                    </div>
                                    <div className={'w-full flex-col'}>
                                        <button onClick={saveChangesHandler}
                                                className={'bg-blue-200 appearance-none border rounded w-full py-2 px-3 leading-tight'}>Принять
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </>}
            </div>
        </div>
    )
}
