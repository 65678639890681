import React from "react";
import {ISupplyProductChange} from "../../models";
import {cutTags} from "../selectors/functions";

export interface SupplyProductChangesListItemProps {
    supplyProductChange: ISupplyProductChange
}

export function SupplyProductChangesListItem({supplyProductChange}: SupplyProductChangesListItemProps) {
    return (
        <tr>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 border-l-0 max-[950px]:hidden'}>
                {`${cutTags(supplyProductChange.supply.name)} (ID=${supplyProductChange.supply.id})`}
            </td>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 max-[950px]:hidden'}>
                {supplyProductChange.product_id}
            </td>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 max-[950px]:hidden'}>
                {supplyProductChange.user ? supplyProductChange.user.login : '-'}
            </td>
            <td className={'p-3 text-center border border-gray-50 border-b-gray-200 max-[950px]:hidden'}>
                {`${(new Date(supplyProductChange.created_at)).toLocaleDateString()} ${(new Date(supplyProductChange.created_at)).toLocaleTimeString()}`}
            </td>
            <td className={'p-3 pl-5 border border-gray-50 border-b-gray-200 border-r-0 max-[950px]:hidden'}>
                {
                    'Цена: ' + (supplyProductChange.state.value.price_in_centi_units.value_in_centi_units / 100) + ' ' + supplyProductChange.state.value.price_in_centi_units.currency
                }<br/>{
                'Тип сохранения цены: ' + (supplyProductChange.state.value.is_price_manual ? 'вручную' : 'расчетная')
            }<br/>{
                'Остаток: ' + (supplyProductChange.state.value.stocks)
            }<br/>{
                'Тип сохранения остатка: ' + (supplyProductChange.state.value.is_stocks_manual ? 'вручную' : 'расчетный')
            }
            </td>
            <td className={'p-3 w-full border break-words border-b-gray-300 border-r-0 border-l-0 min-[951px]:hidden'}>
                <div className={'flex w-full justify-center max-[700px]:flex-wrap'}>
                    <div className={'mr-5 flex-col justify-start w-full'}>
                        <div>
                            <b>Поставщик:</b> {`${cutTags(supplyProductChange.supply.name)} (ID=${supplyProductChange.supply.id})`}
                        </div>
                        <div>
                            <b>Товар:</b> {supplyProductChange.product_id}
                        </div>
                        {supplyProductChange.user && <div>
                            <b>Пользователь:</b> {supplyProductChange.user.login}
                        </div>}
                    </div>
                    <div className={'flex-col justify-start w-full'}>
                        <b>Предыдущее состояние:</b><br/>
                        {
                            'Цена: ' + (supplyProductChange.state.value.price_in_centi_units.value_in_centi_units / 100) + ' ' + supplyProductChange.state.value.price_in_centi_units.currency
                        }<br/>{
                            'Тип сохранения цены: ' + (supplyProductChange.state.value.is_price_manual ? 'вручную' : 'расчетная')
                        }<br/>{
                            'Остаток: ' + (supplyProductChange.state.value.stocks)
                        }<br/>{
                            'Тип сохранения остатка: ' + (supplyProductChange.state.value.is_stocks_manual ? 'вручную' : 'расчетный')
                        }
                    </div>
                </div>
                <div className={'mt-5  text-gray-600'}>
                    <p>Дата
                        обновления: <b>{`${(new Date(supplyProductChange.created_at)).toLocaleDateString()} ${(new Date(supplyProductChange.created_at)).toLocaleTimeString()}`}</b>
                    </p>
                </div>
            </td>
        </tr>
    )
}
