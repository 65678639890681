import React from "react";
import {ProductPriceRuleLogEntriesList} from "../components/product-price-rule-log-entries/ProductPriceLogEntriesList";

export function ProductPriceRuleLogEntriesPage() {
    return (
        <div className={'m-5'}>
            <ProductPriceRuleLogEntriesList/>
        </div>
    )
}
