import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Error} from "../Error";
import {Loader} from "../Loader";
import {Pagination} from "../Pagination";
import {useGetProductPriceRuleLogEntriesQuery} from "../../store/product-price-rule-log-entries-api";
import {ProductPriceRuleLogEntriesListItem} from "./ProductPriceRuleLogEntriesListItem";

export function ProductPriceRuleLogEntriesList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState<number>(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
    const [perPage, setPerPage] = useState<number>(Number(searchParams.get("per-page")) ? Number(searchParams.get("per-page")) : 50)
    const [productPriceRuleID, setProductPriceRuleID] = useState<string>(searchParams.get('product-price-rule-id') ? String(searchParams.get('product-price-rule-id')) : '')
    const [userLogin, setUserLogin] = useState<string>(searchParams.get("login") ? String(searchParams.get("login")) : '')
    const {data: response, isLoading, error } = useGetProductPriceRuleLogEntriesQuery({page: Number(page), perPage: Number(perPage), productPriceRuleID: (productPriceRuleID === '' ? null : productPriceRuleID), userLogin: (userLogin === '' ? null : userLogin)})

    useEffect(() => {
        setPage(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
        setPerPage(Number(searchParams.get("per-page")) ? Number(searchParams.get("per-page")) : 50)
        setProductPriceRuleID(searchParams.get('product-price-rule-id') ? String(searchParams.get('product-price-rule-id')) : '')
        setUserLogin(searchParams.get("login") ? String(searchParams.get("login")) : '')
    }, [searchParams]);

    const selectPageHandler = (page: number) => {
        searchParams.set('page', String(page))
        setSearchParams(searchParams)
    }
    const selectPerPageHandler = (perPage: number) => {
        searchParams.set('per-page', String(perPage))
        setSearchParams(searchParams)
    }

    const productPriceRuleIDHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        searchParams.set('product-price-rule-id', String(event.target.value))
        setSearchParams(searchParams)
    }

    const userLoginHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        searchParams.set('login', String(event.target.value))
        setSearchParams(searchParams)
    }

    return (
        <div className={'m-5 max-[700px]:m-0'}>
            <div className={'content-center'}>
                <h1 className={'text-2xl'}>Лог правил наценки</h1>
            </div>
            <div className={'min-[1851px]:w-[1600px]'}>
                <div className={'mt-2'}>
                    <input
                        placeholder={'ID правила наценки'}
                        onChange={productPriceRuleIDHandler}
                        className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                        type={'text'} value={productPriceRuleID ?? ''}/>
                </div>
                <div className={'mt-2'}>
                    <input
                        placeholder={'Логин'}
                        onChange={userLoginHandler}
                        className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                        type={'text'} value={userLogin ?? ''}/>
                </div>
                <div>
                    {error && <Error error={JSON.stringify(error)}/>}
                    {isLoading && <Loader/>}
                    <Pagination
                        initPage={page}
                        initPageSize={perPage}
                        allCount={response ? response.all_count : 0}
                        onSelectPageHandler={selectPageHandler}
                        onSelectPageSizeHandler={selectPerPageHandler}/>
                </div>
                <table className={'table-auto mt-5 w-[1600px] border-collapse max-[1850px]:w-full'}>
                    <thead className={'bg-blue-200 max-[950px]:hidden'}>
                    <tr>
                        <th className={'border border-gray-200 '}>
                            ID правила наценки
                        </th>
                        <th className={'border border-gray-200'}>
                            Действие
                        </th>
                        <th className={'border border-gray-200'}>
                            Пользователь
                        </th>
                        <th className={'border border-gray-200'}>
                            Дата
                        </th>
                        <th className={'border border-gray-200'}>
                            Лог
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {response && response.product_price_rule_log_entries.map(product_price_rule_log_entry =>
                            <ProductPriceRuleLogEntriesListItem productPriceRuleLogEntry={product_price_rule_log_entry} key={product_price_rule_log_entry.id} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
