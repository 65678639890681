import {ICreateOrUpdateSupplyProduct, ISupplyExternalWItemSupplyProduct} from "../models";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {BaseQueryError} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {prepareHeadersHandler, responseHandler} from "./functions";

interface SupplyExternalAndSupplyProductListResponse {
    supply_external_witems_supply_products: ISupplyExternalWItemSupplyProduct[]
    page: number
    all_count: number
    total_page: number
}

export const supplyProductApi = createApi({
    reducerPath: 'supplyProductApi',
    tagTypes: ['SupplyProducts'],
    baseQuery: fetchBaseQuery(
        {
            baseUrl: '/api/v1/supply-product',
            prepareHeaders: prepareHeadersHandler,
            responseHandler: responseHandler,
        },
    ),
    endpoints: (builder) => ({
        getSupplyProducts: builder.query<SupplyExternalAndSupplyProductListResponse, { page: number, pageSize: number, supplyID: number, orderBy: string, isAsc: boolean, witemIDs: number[], brandIDs: number[], wareIDs: number[], dateFrom: string|null, dateTo: string|null, }>({
            query: (arg) => ({
                url: 'supply-external-and-supply-product-list',
                method: 'POST',
                body: {
                    "page": arg.page,
                    "page_size": arg.pageSize,
                    "supply_id": arg.supplyID,
                    "order_by": arg.orderBy,
                    "is_asc": arg.isAsc,
                    "witem_ids": arg.witemIDs,
                    "brand_ids": arg.brandIDs,
                    "ware_ids": arg.wareIDs,
                    "date_from": arg.dateFrom,
                    "date_to": arg.dateTo
                },
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            providesTags: (result) => result
                ? [
                    ...result.supply_external_witems_supply_products.map(({supply_code}) => ({type: 'SupplyProducts' as const, supply_code})),
                    {type: 'SupplyProducts', id: 'LIST'},
                ]
                : [{type: 'SupplyProducts', id: 'LIST'}],
        }),
        createSupplyProducts: builder.mutation<ICreateOrUpdateSupplyProduct[], any>({
            query: (supplyProducts: ICreateOrUpdateSupplyProduct[]): any => ({
                url: 'create',
                method: 'POST',
                body: {
                    supplies_dto: supplyProducts
                }
            }),
            transformErrorResponse: (baseQueryReturnValue: BaseQueryError<any>): string => {
                return baseQueryReturnValue.data.error.message
            },
            invalidatesTags: [{type: 'SupplyProducts', id: 'LIST'}]
        }),
    })
})

export const {
    useGetSupplyProductsQuery,
    useCreateSupplyProductsMutation
} = supplyProductApi
