import {IWare} from "../../models";
import {useGetWaresQuery} from "../../store/wares-api";
import Select, {createFilter, MultiValue, SingleValue, StylesConfig} from "react-select";
import {Error} from "../Error";
import { CustomOption } from "./CustomSelectOption";
import {cutTags} from "./functions";
import {useEffect, useState} from "react";
import MenuList from "./MenuList";


interface WareSelectorProps {
    onSelect: (ware: IWare) => void
    initWareID: number[] | number | null
    isDisabled: boolean
    isClearable: boolean
    onSelectNull: () => void
    isCustomStyles?: boolean
    isMulti?: boolean
    onMultiSelect?: (ware: IWare[]) => void
}

export function WareSelector(
    {onSelect, initWareID, isDisabled = false, isClearable = false, onSelectNull = () => {}, isCustomStyles = true, isMulti = false, onMultiSelect = () => {}}: WareSelectorProps
){
    const {data: wares = [], isLoading, error} = useGetWaresQuery(0)

    const flatWares = createFlatWaresForSelect(wares)

    const initWareIndex = isMulti ? flatWares.map((flatWare, index) => {
        if ((initWareID as number[]).includes(flatWare.id)) {
            return index
        }
    }) : flatWares.findIndex((ware: IWare) => ware.id === initWareID)

    useEffect(() => {
        if (isMulti && !isLoading) {
            const wares: IWare[] = [];
            (initWareID as number[]).forEach((wareID) => {
                const index = flatWares.findIndex((flatWare: IWare) => flatWare.id === wareID && flatWare.is_active_laptop);
                if (index !== -1) {
                    wares.push(flatWares[index]);
                }
            })
            onMultiSelect(wares)
        } else if (!isMulti &&
            !isLoading &&
            flatWares.findIndex((flatWare: IWare) => flatWare.id === initWareID && flatWare.is_active_laptop) === -1
        ) {
            onSelectNull()
        }
    }, [wares]);

    const onChangeHandler = (newValue: SingleValue<IWare> | MultiValue<IWare>) => {
        if (newValue === null) {
            if (isClearable) {
                onSelectNull()
            }
            return
        }
        isMulti ? onMultiSelect(newValue as IWare[]) : onSelect(newValue as IWare);
    }

    const customStyles: StylesConfig<IWare> = {
        control: (styles) => ( isCustomStyles ? {...styles, cursor: 'text'} : {...styles}),
        indicatorsContainer: (styles) => ( isCustomStyles ? {...styles, cursor: 'pointer'} : {...styles}),
        option: (styles, { data  }) => {
            return {
                ...styles,
                cursor: isCustomStyles && data.is_active_laptop ? 'pointer' : styles.cursor,
                paddingLeft: data.nesting_level ? data.nesting_level * 25 + 'px' : styles.paddingLeft,
                fontWeight: data.nesting_level ? styles.fontWeight : 'bold',
            }
        },
        multiValueRemove: (styles, {data}) => ( isCustomStyles && !data.is_active_laptop ? {...styles, display: 'none'} : {...styles}),
    }
    const [inputValue, setInputValue] = useState('')

    return (
        <div>
            {error && <Error error={JSON.stringify(error)}/>}
            <Select
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                hideSelectedOptions={false}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isOptionDisabled={(option) => !option.is_active_laptop}
                isLoading={isLoading}
                placeholder={'Выбор каталога'}
                options={flatWares}
                getOptionLabel={(ware: IWare) => `${cutTags(ware.name)} (ID=${ware.id})`}
                getOptionValue={(ware: IWare) => String(ware.id)}
                onChange={onChangeHandler}
                value={isMulti ? (initWareIndex as number[]).map(wareIndex => flatWares[wareIndex]) : flatWares[initWareIndex as number]}
                styles={customStyles}
                components={{ Option: CustomOption, MenuList: MenuList }}
                classNamePrefix="custom-select"
                filterOption={createFilter({ignoreAccents: false})}
                onInputChange={(value, meta) => {
                    if (meta.action !== "set-value") setInputValue(value)
                }}
                inputValue={inputValue}
            />
        </div>
    )
}

function createFlatWaresForSelect(wares: IWare[]): IWare[]{
    let leafs: IWare[] = [];

    wares.forEach((ware:IWare)=> {
        leafs.push(ware)
        if (ware.children_wares.length > 0){
            let childLeafs = createFlatWaresForSelect(ware.children_wares)
            childLeafs.forEach((childWare:IWare) : void => {
                leafs.push({
                    id: childWare.id,
                    name: childWare.name,
                    children_wares: childWare.children_wares,
                    nesting_level: (childWare.nesting_level ? childWare.nesting_level : 0) + 1,
                    is_active_laptop: childWare.is_active_laptop,
                })
            })
        }
    })

    return leafs
}
