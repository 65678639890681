import {Link} from "react-router-dom";
import React, {useState} from "react";

export interface NavigationLinkProps {
    link: string|string[]
    name: string|string[]
    listName?: string
    linkStyle?: (selectedLink: string) => string
    linkClickHandler?: (selectedLink: string) => void
    isListSelected?: boolean
    listCover?: React.ReactSVGElement
}

export function NavigationLink({link, name, listName, linkStyle, linkClickHandler, isListSelected, listCover}: NavigationLinkProps) {
    const listState = () => {
        if (!isOpen) {
            return (
                <span className="mr-2"><svg className={'w-3 h-6 text-gray-800 dark:text-white '} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"></path>
                </svg></span>
            )
        } else {
            return (
                <span className="mr-2"><svg className={'w-3 h-6 text-gray-800 dark:text-white '} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"></path>
                </svg></span>
            )
        }
    }

    const [isOpen, setIsOpen] = useState<boolean>(isListSelected ? isListSelected : false)
    if (Array.isArray(link) && Array.isArray(name)) {
        return (
            <>
                <a onClick={() => setIsOpen(!isOpen)}
                   className={'flex justify-between items-center p-2 mr-0 text-base font-normal text-gray-900 cursor-pointer select-none rounded-none rounded-l-lg max-[900px]:mr-3 max-[900px]:rounded-r-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700'}
                >
                    <div className={'flex items-center'}>
                        {listCover && <span className={'mr-1'}>{listCover}</span>}
                        {listName ? listName : ''}
                    </div>
                    {listState()}
                </a>
                {isOpen && link.map((linkItem, index) => {
                    return <Link key={index} to={linkItem} onClick={() => linkClickHandler ? linkClickHandler(linkItem) : null}
                                 className={'flex items-center p-2 ml-3 mr-0 text-base font-normal text-gray-900 rounded-none rounded-l-lg max-[900px]:mr-3 max-[900px]:rounded-r-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700' + (linkStyle ? linkStyle(linkItem) : '')}
                    >
                        {name[index]}
                    </Link>
                })}
            </>
        )
    }
    return (
        <Link to={(link as string)} onClick={() => linkClickHandler ? linkClickHandler(link as string) : null}
              className={'flex items-center p-2 mr-0 text-base font-normal text-gray-900 rounded-none rounded-l-lg max-[900px]:mr-3 max-[900px]:rounded-r-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700' + (linkStyle ? linkStyle(link as string) : '')}
        >
            {name}
        </Link>
    )
}
