import * as React from 'react';

export function calcOptionsLength (options: any) {
    options = options || [];
    const head = options[0] || {};
    const isGrouped = head.options !== undefined;

    return isGrouped
        ? options.reduce((result: any, group: any) => result + group.options.length, 0)
        : options.length;
}

export function flattenGroupedChildren(children: any) {
    return children.reduce((result: any, child: any) => {
        if (child.props.children != null && typeof child.props.children === "string") {
            return [...result, child];
        } else {
            const {
                props: { children: nestedChildren = [] },
            } = child;

            return [...result, React.cloneElement(child, { type: "group" }, []), ...nestedChildren];
        }
    }, []);
}

export function isFocused ({ props: { isFocused } }: any) {
    return isFocused === true;
}

export function getCurrentIndex (children: any) {
    return Math.max(
        children.findIndex(isFocused),
        0
    );
}

export function createGetHeight ({
     groupHeadingStyles,
     noOptionsMsgStyles,
     optionStyles,
     loadingMsgStyles,
 }: any) {
    return function getHeight (child: any) {
        const {
            props: {
                type,
                children,
                inputValue,
                selectProps: {
                    noOptionsMessage,
                    loadingMessage,
                },
            }
        } = child;

        if (type === 'group') {
            const { height = 25 } = groupHeadingStyles;
            return height;
        }
        else if (type === 'option') {
            const { height = 41 } = optionStyles;
            return height;
        }
        else if (typeof noOptionsMessage === 'function' && children === noOptionsMessage({ inputValue })) {
            const { height = 35 } = noOptionsMsgStyles;
            return height;
        }
        else if (typeof loadingMessage === 'function' && children === loadingMessage({ inputValue })) {
            const { height = 35 } = loadingMsgStyles;
            return height;
        }
        else {
            return 35;
        }
    };
}
