import {Error} from "../Error";
import {Loader} from "../Loader";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ProductPriceRuleItem} from "./ProductPriceRuleItem";
import {useGetProductPriceRulesQuery} from "../../store";
import {ModalContext} from "../../context/ModalContext";
import {Modal} from "../Modal";
import {CreateProductPriceRule} from "./CreateProductPriceRule";
import {useSearchParams} from "react-router-dom";
import {Pagination} from "../Pagination";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css"
import "react-calendar/dist/Calendar.css"
import {ProductPriceRuleItemProperties} from "./ProductPriceRuleItemProperties";
import {IProductPriceRule} from "../../models";
import {BrandSelector} from "../selectors/BrandSelector";
import {WareSelector} from "../selectors/WareSelector";
import {CitySelector} from "../selectors/CitySelector";
import {IBrand, ICity, IWare} from "../../models";
import CustomInput from "../CustomInput";
import Select, {StylesConfig} from "react-select";

export function ProductPriceRuleList() {

    const prepareIDsFromUrl = (value: string): number[] => {
        if (value !== '') {
            let ids = value.split(',');
            return ids.map((id) => {
                return Number(id);
            })
        }

        return [];
    }

    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState<number>(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
    const [perPage, setPerPage] = useState<number>(Number(searchParams.get("per-page")) ? Number(searchParams.get("per-page")) : 50)
    const [wareIDs, setWareIDs] = useState<number[]>(searchParams.get('ware-ids') ? prepareIDsFromUrl(String(searchParams.get('ware-ids'))) : [])
    const [cityID, setCityID] = useState<number|null>(searchParams.get('city-id') ? Number(searchParams.get('city-id')) : null)
    const [brandIDs, setBrandIDs] = useState<number[]>(searchParams.get('brand-ids') ? prepareIDsFromUrl(String(searchParams.get('brand-ids'))) : [])
    const [wItemIDs, setWItemIDs] = useState<string>(searchParams.get('witem-ids') ? String(searchParams.get('witem-ids')) : '')
    const [userLogin, setUserLogin] = useState<string>(searchParams.get("login") ? String(searchParams.get("login")) : '')
    const [isAsc, setIsAsc] = useState<boolean>(false)
    const [dateFrom, setDateFrom] = useState<string>(searchParams.get('date-from') ? String(searchParams.get('date-from')) : '')
    const [dateTo, setDateTo] = useState<string>(searchParams.get('date-to') ? String(searchParams.get('date-to')) : '')
    const {data: productPriceRuleResponse, isLoading, error} = useGetProductPriceRulesQuery({page: page, perPage: perPage, isAsc: isAsc, dateFrom: (dateFrom === '' ? null : dateFrom + ' 00:00:00'), dateTo: (dateTo === '' ? null : dateTo + ' 23:59:59'), login: userLogin === '' ? null : userLogin, wareIDs: wareIDs, cityID: cityID, brandIDs: brandIDs, wItemIDs: prepareIDsFromUrl(wItemIDs)})
    const {modal: createModal, open: openCreateModal, close: closeCreateModal} = useContext(ModalContext);
    const [propertiesModal, setPropertiesModal] = useState<boolean>(false);

    useEffect(() => {
        setPage(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1)
        setPerPage(Number(searchParams.get("per-page")) ? Number(searchParams.get("per-page")) : 50)
        setUserLogin(searchParams.get("login") ? String(searchParams.get("login")) : '')
        setIsAsc(searchParams.get('is-asc') === 'true')
        setDateFrom(searchParams.get("date-from") ? String(searchParams.get('date-from')) : '')
        setDateTo(searchParams.get('date-to') ? String(searchParams.get('date-to')) : '')
        setWItemIDs(searchParams.get('witem-ids') ? String(searchParams.get('witem-ids')) : '')
        setWareIDs(searchParams.get('ware-ids') ? prepareIDsFromUrl(String(searchParams.get('ware-ids'))) : [])
        setCityID(searchParams.get('city-id') ? Number(searchParams.get('city-id')) : null)
        setBrandIDs(searchParams.get('brand-ids') ? prepareIDsFromUrl(String(searchParams.get('brand-ids'))) : [])
    }, [searchParams]);

    const selectPageHandler = (page: number) => {
        searchParams.set('page', String(page))
        setSearchParams(searchParams)
    }
    const selectPerPageHandler = (perPage: number) => {
        searchParams.set('per-page', String(perPage))
        setSearchParams(searchParams)
    }

    const userLoginHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        searchParams.set('login', String(event.target.value))
        setSearchParams(searchParams)
    }

    const isAscHandler = () => {
        searchParams.set('is-asc', String(!isAsc))
        setSearchParams(searchParams)
    }

    const getArrowFilters = (color: string = 'text-gray-800') => {
        if (isAsc) {
            return (
                <span className="ml-2"><svg className={'w-3 h-6 dark:text-white ' + color} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"></path>
                </svg></span>
            )
        } else {
            return (
                <span className="ml-2"><svg className={'w-3 h-6 dark:text-white ' + color} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"></path>
                </svg></span>
            )
        }
    }

    const dateHandler = (value: any, dateKey: string): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        };

        value = value !== null ? value.toLocaleDateString('en-US', options) : ''
        let lengthYear = value !== '' ? value.split('/')[2].length : 4

        if (lengthYear === 4) {
            searchParams.set(dateKey, String(value))
            setSearchParams(searchParams)
        }
    }

    const blockRef = useRef<HTMLDivElement>(null)
    const [block, setBlock] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            const yPos = blockRef.current?.getBoundingClientRect().y;
            setBlock(!!(yPos && yPos <= -20));
            sessionStorage.setItem('scrollPos', String(window.document.getElementById('main')?.scrollTop));
        };
        window.document.getElementById('main')?.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.document.getElementById('main')?.removeEventListener('scroll', handleScroll);
    }, []);

    const [selectedRule, setSelectedRule] = useState<IProductPriceRule|null>(productPriceRuleResponse ? productPriceRuleResponse.product_price_rule[0] : null)
    const [menuLoading, setMenuLoading] = useState<boolean>(false)

    useEffect(() => {
        setSelectedRule(productPriceRuleResponse ? productPriceRuleResponse.product_price_rule[0] : null)
        window.document.getElementById('main')?.scrollTo(0, sessionStorage.getItem('scrollPos') ? Number(sessionStorage.getItem('scrollPos')) : 0);
    }, [productPriceRuleResponse]);
    const selectRuleHandler = (selectedRule: IProductPriceRule|null): void => {
        if (selectedRule === null) {
            setSelectedRule(productPriceRuleResponse ? productPriceRuleResponse.product_price_rule[0] : null)
        }
        setSelectedRule(selectedRule)
        if (isMini) {
            setPropertiesModal(true)
        }
    }

    const wItemsHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        let value = event.target.value.replace(/[^\d,]/g, ' ')
        let index = value.indexOf(' ')
        searchParams.set('witem-ids', value.replace(' ', ''))
        await setSearchParams(searchParams);
        if (index !== -1) {
            event.target.setSelectionRange(index,index);
        }
    }

    const multiSelectHandler = (entities: IWare[]|IBrand[], selectKey: string): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        let ids = entities.map((entity) => {
            return entity.id
        })
        searchParams.set(selectKey, ids.join(','))
        setSearchParams(searchParams)
    }

    const selectCityHandler = (city: ICity|null): void => {
        selectPageHandler(1)
        selectPerPageHandler(50)
        searchParams.set('city-id', String(city ? city.city_id : ''))
        setSearchParams(searchParams)
    }

    const menuLoadingHandler = (value: boolean) => {
        setMenuLoading(value)
    }

    const [isMini, setMini] = useState<boolean>(false);
    useEffect(() => {
        const handleResize = () => {
            let width = document.body.clientWidth;
            if (width <= 1144) {
                setMini(true);
            } else {
                setMini(false);
                setPropertiesModal(false);
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize, { passive: true });
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const customStyles: StylesConfig = {
        option: (styles) => ({...styles, cursor: 'pointer'}),
        control: (styles) => ({...styles, cursor: 'text'}),
        indicatorsContainer: (styles) => ({...styles, cursor: 'pointer'})
    }

    return (
        <div>
            <div className={'content-center'}>
                <h1 className={'text-2xl'}>Правила наценок товаров</h1>
            </div>
            {createModal && <Modal title={'Создать правило'} onClose={closeCreateModal}>
                <CreateProductPriceRule closeModal={closeCreateModal}/>
            </Modal>}
            {propertiesModal && <Modal title={'Редактирование правила'} onClose={() => setPropertiesModal(false)}>
                <ProductPriceRuleItemProperties rule={selectedRule}
                                                deleteRule={() => (selectRuleHandler(null))}
                                                loading={menuLoadingHandler}
                                                closeModal={() => setPropertiesModal(false)}/>
            </Modal>}
            <div className={'relative'}>
                <div className={'mt-2'}>
                    <CustomInput
                        placeholder={'Логин'}
                        onChange={userLoginHandler}
                        className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                        type={'text'} value={userLogin ?? ''}/>
                </div>
                <div className={'mt-2'}>
                    <WareSelector
                        onSelect={() => {
                        }}
                        initWareID={wareIDs}
                        isDisabled={false}
                        isClearable={true}
                        onSelectNull={() => {
                            multiSelectHandler([], 'ware-ids')
                        }}
                        isMulti={true}
                        onMultiSelect={(wares) => {
                            multiSelectHandler(wares, 'ware-ids')
                        }}/>
                </div>
                <div className={'mt-2'}>
                    <CitySelector onSelect={(city) => {
                        selectCityHandler(city)
                    }} initCityID={cityID} onSelectNull={() => {
                        selectCityHandler(null)
                    }}
                                  isClearable={true} isDisabled={false}/>
                </div>
                <div className={'mt-2'}>
                    <BrandSelector
                        onSelect={() => {
                        }}
                        initBrandID={brandIDs}
                        isDisabled={false}
                        isClearable={true}
                        onSelectNull={() => {
                            multiSelectHandler([], 'brand-ids')
                        }}
                        isMulti={true}
                        onMultiSelect={(brands) => {
                            multiSelectHandler(brands, 'brand-ids')
                        }}/>
                </div>
                <div className={'mt-2'}>
                    <CustomInput
                        value={wItemIDs ?? ''}
                        onChange={wItemsHandler}
                        className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                        type="text" placeholder="ID товара"/>
                </div>
                <div className={'relative'}>
                    <div id={'date-block'} className={'mt-6 flex max-[460px]:flex-col'}>
                        <div className={'relative'}>
                            <DatePicker
                                id={'date-picker-from'}
                                className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                                dayPlaceholder={'дд'}
                                monthPlaceholder={'мм'}
                                yearPlaceholder={'гггг'}
                                format={'dd.MM.y'}
                                maxDate={new Date(dateTo)}
                                onChange={(value: any) => {
                                    dateHandler(value, 'date-from')
                                }}
                                value={dateFrom}/>
                            <label htmlFor="date-picker-from"
                                   className="z-1 absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                C
                            </label>
                        </div>
                        <div className={'mx-2.5 pt-2 max-[460px]:hidden'}>
                            <svg className="w-6 h-6 text-gray-800" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M5 12h14"/>
                            </svg>
                        </div>
                        <div className={'relative max-[460px]:mt-4'}>
                            <DatePicker
                                id={'date-picker-to'}
                                className={'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'}
                                dayPlaceholder={'дд'}
                                monthPlaceholder={'мм'}
                                yearPlaceholder={'гггг'}
                                format={'dd.MM.y'}
                                minDate={new Date(dateFrom)}
                                onChange={(value: any) => {
                                    dateHandler(value, 'date-to')
                                }}
                                value={dateTo}
                            />
                            <label htmlFor="date-picker-to"
                                   className="z-1 absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                До
                            </label>
                        </div>
                    </div>
                    <label htmlFor="date-block"
                           className="z-1 absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 -top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-12 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                        Дата обновления
                    </label>
                </div>
                <div className={'relative mt-5 min-[651px]:hidden'}>
                    <Select
                        id={'sort-rule'}
                        className={'appearance-none rounded w-full py-2 leading-tight focus:outline-none focus:shadow-outline'}
                        placeholder={'Сортировка'}
                        options={[
                            {
                                id: 1,
                                value: 'Дата, по возрастанию',
                            } as any,
                            {
                                id: 2,
                                value: 'Дата, по убыванию',
                            } as any,
                        ]}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.id}
                        onChange={() => isAscHandler()}
                        styles={customStyles}
                        value={isAsc ? {
                            id: 1,
                            value: 'Дата, по возрастанию',
                        } as any : {
                            id: 2,
                            value: 'Дата, по убыванию',
                        } as any}/>
                    <label htmlFor="sort-rule"
                           className="z-1 absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                        Сортировка
                    </label>
                </div>
                <div>
                    {error && <Error error={JSON.stringify(error)}/>}
                    {(isLoading || menuLoading) && <Loader/>}
                    <Pagination
                        initPage={page}
                        initPageSize={perPage}
                        allCount={productPriceRuleResponse ? productPriceRuleResponse.all_count : 0}
                        onSelectPageHandler={selectPageHandler}
                        onSelectPageSizeHandler={selectPerPageHandler}/>
                </div>
                <div ref={blockRef} className={'flex flex-row relative min-w-[700px] w-full max-[1144px]:min-w-0'}>
                    <div
                        className={'relative w-[calc(100vw-14.5rem-500px)] max-[1380px]:w-[calc(100vw-12.5rem-350px)] max-[1144px]:w-full min-[1145px]:overflow-x-auto'}>
                        <table className={'table-auto mt-5 border-collapse w-full'}>
                            <thead className={'bg-blue-200 max-[650px]:hidden'}>
                            <tr>
                                <th className={'border border-gray-200 min-w-[120px] max-[1190px]:min-w-0'}>
                                    Город
                                </th>
                                <th className={'border border-gray-200 min-w-[120px] max-[1190px]:min-w-0'}>
                                    Каталог
                                </th>
                                <th className={'border border-gray-200 min-w-[120px] max-[1190px]:min-w-0'}>
                                    Бренд
                                </th>
                                <th className={'border border-gray-200 min-w-[80px] max-[1190px]:min-w-0'}>
                                    Товар
                                </th>
                                <th className={'border border-gray-200 w-[130px] px-1'}>
                                    <button className="flex align-baseline" onClick={() => {
                                        isAscHandler()
                                    }}>
                                        <span>Дата обновления</span>
                                        {getArrowFilters()}
                                    </button>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {productPriceRuleResponse && productPriceRuleResponse.product_price_rule.map(rule =>
                                <ProductPriceRuleItem rule={rule} key={rule.id} selectRule={selectRuleHandler}
                                                      style={(rule === selectedRule) ? 'bg-gray-50' : ''}/>
                            )}
                            </tbody>
                        </table>
                        <div className={'absolute right-0 bottom-1 z-10'}>
                            <div className={'fixed bottom-1 translate-x-[-100%]'}>
                                <button
                                    className={'rounded-full bg-red-700 text-white text-2xl px-4 py-2 mb-4 mr-2'}
                                    onClick={openCreateModal}
                                >Создать
                                </button>
                            </div>
                        </div>
                    </div>
                    {block && <div className={'mt-5 max-[1144px]:hidden'}>
                        <div className={'w-[500px] h-screen max-[1380px]:w-[350px]'}></div>
                    </div>}
                    <div className={' mt-5 max-[1144px]:hidden ' + (block ? 'absolute top-0 right-0' : '')}>
                        <div
                            className={'w-[500px] max-[1380px]:w-[350px] ' + (block ? 'fixed top-0 translate-x-[-100%] h-full' : '')}>
                            <ProductPriceRuleItemProperties rule={selectedRule}
                                                            deleteRule={() => (selectRuleHandler(null))}
                                                            loading={menuLoadingHandler}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
